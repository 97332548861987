import "./Customer.scss";
import { useForm } from "react-hook-form";
import { useState, useMemo, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Swal from "sweetalert2";
import {
  Autocomplete,
  LoadScript,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";

function Customer({ setShow, toggleModal, showLoading, values }) {
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const mapContainerStyle = {
    height: "300px",
    width: "100%",
    marginTop: "1rem",
  };
  const fields = ["formatted_address", "name", "geometry.location"];
  const restrictions = {
    country: "CL",
  };
  const libraries = useMemo(() => ["places"], []);
  const account = JSON.parse(localStorage.getItem("account"));
  const [directions, setDirections] = useState({});
  const [markerPosition, setMarkerPosition] = useState({
    lat: -33.4372,
    lng: -70.6506,
  });
  const [searchResult, setSearchResult] = useState(null);
  const [map, setMap] = useState(null);
  const companyId = account.access.at(0);

  useEffect(() => {
    if (values) {
      setValue("name", values.name);
      setValue("rut", values.rut);
      setValue("email", values.email);
      setValue("phone", values.phone);
      setValue("address", values.address);
      setValue("formatedAddress", values.formatedAddress);
      setValue("area", values.area);
      setValue("lat", values.lat);
      setValue("lng", values.lng);
      setValue("id", values.id);

      // setDirections({
      //   lat: values.lat,
      //   lng: values.lng,
      //   address: values.address,
      //   formatedAddress: values.formatedAddress,
      // });
      if (values?.lat && values?.lng) {
        onLoadMap(map);
        map.setZoom(15);
      } else {
        if (map) {
          setMarkerPosition({
            lat: -33.4372,
            lng: -70.6506,
          });
          map.setZoom(10);
          map.panTo({
            lat: -33.4372,
            lng: -70.6506,
          });
        }
      }
    }
  }, [values]);

  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  const handlePlaceChanged = () => {
    if (searchResult) {
      const location = {
        lat: searchResult.getPlace().geometry.location.lat(),
        lng: searchResult.getPlace().geometry.location.lng(),
      };
      console.log(location);
      if (map) {
        map.panTo(location);
        setMarkerPosition(location);
        map.setZoom(15);
      }
      const place = searchResult.getPlace();
      setDirections({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address: place.name,
        formatedAddress: place.formatted_address,
      });
    } else {
      console.log("no hay resultados");
    }
  };

  const handleCancel = () => {
    toggleModal();
    setDirections({});
    reset(values);
  };

  const onUnmount = () => {
    setMap(null);
  };

  const onLoadMap = (map) => {
    let center = {
      lat: -33.4372,
      lng: -70.6506,
    };
    map.maxDefaultZoom = 15;
    
    map.setZoom(10);
    if (Object.keys(values).length > 0) {
      center = {
        lat: values.lat,
        lng: values.lng,
      };
      setMarkerPosition(center);
      map.panTo(center);
    }
    setMap(map);
  };

  const onSubmit = async (data) => {
    showLoading(true);
    try {
      if (!data.id) {
        data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
        data.createdBy = firebase.auth().currentUser.uid;
        data.deletedAt = null;
        data.deletedBy = null;
        data.lat = directions.lat;
        data.lng = directions.lng;
        data.address = directions.address;
        data.formatedAddress = directions.formatedAddress;
      }
      if (data.id) {
        data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
        data.updatedBy = firebase.auth().currentUser.uid;

        if (Object.keys(directions).length > 0) {
          data.lat = directions.lat;
          data.lng = directions.lng;
          data.address = directions.address;
          data.formatedAddress = directions.formatedAddress;
        } else {
          data.lat = values.lat;
          data.lng = values.lng;
          data.address = values.address;
          data.formatedAddress = values.formatedAddress;
        }
      }

      const doc = firebase
        .firestore()
        .collection("companies")
        .doc(companyId)
        .collection("customers")
        .doc(data.id);
      data.id = doc.id;
      await doc.set(data, { merge: true });
      Swal.fire({
        title: "Registro guardado exitosamente",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
      toggleModal();
      setDirections({});
    } catch (e) {
      alert(e.message);
    }
    showLoading(false);
    setDirections({});
    reset(values);
  };

  return (
    <div
      aria-hidden="true"
      className={`fixed top-0 left-0 right-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50 w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen ${
        setShow ? "block" : "hidden"
      }`}
    >
      {/* <!-- Main modal --> */}
      <div className="relative w-full max-w-md max-h-full">
        {/* <!-- Modal content --> */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-hide="authentication-modal"
            onClick={handleCancel}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
              Ingrese los datos del cliente
            </h3>
            <form className="space-y-2">
              <div>
                <label
                  htmlFor="name"
                  className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Nombre
                </label>
                <input
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                    errors.name && "border-rose-500"
                  }`}
                  placeholder="Juanita Del Carmen"
                  id="name"
                  {...register("name", { required: true })}
                />
                {errors.name && errors.name.type === "required" && (
                  <span className="text-pink-700">Este campo es requerido</span>
                )}
              </div>
              <div>
                <label
                  htmlFor="rut"
                  className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Rut
                </label>
                <input
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                    errors.rut && "border-rose-700"
                  }`}
                  placeholder="12.345.678-9"
                  id="rut"
                  {...register("rut", { required: false })}
                />
              </div>
              <div>
                <label
                  className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                  htmlFor="rut"
                >
                  Telefono
                </label>
                <input
                  autoComplete="off"
                  type="number"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                    errors.phone && "border-rose-700"
                  }`}
                  placeholder="9 1234 5678"
                  id="phone"
                  {...register("phone", {
                    required: true,
                    minLength: 9,
                    maxLength: 9,
                  })}
                />
                {errors.phone && errors.phone.type === "required" && (
                  <span className="text-pink-700">Este campo es requerido</span>
                )}
                {errors.phone && errors.phone.type === "minLength" && (
                  <span className="text-pink-700">
                    El telefono debe tener 9 digitos
                  </span>
                )}
                {errors.phone && errors.phone.type === "maxLength" && (
                  <span className="text-pink-700">
                    El telefono debe tener 9 digitos
                  </span>
                )}
              </div>
              <div>
                <label
                  className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                  htmlFor="email"
                >
                  Correo electrónico
                </label>
                <input
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                    errors.email && "border-rose-700"
                  }`}
                  placeholder="cliente@mail.com"
                  id="email"
                  {...register("email", {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                />
                {errors.email && errors.email.type === "required" && (
                  <span className="text-rose-700">Este campo es requerido</span>
                )}
                {errors.email && errors.email.type === "pattern" && (
                  <span className="text-rose-700">Formato inválido</span>
                )}
              </div>
              <div>
                <label
                  className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                  htmlFor="address"
                >
                  Dirección
                </label>
                <LoadScript
                  googleMapsApiKey="AIzaSyD0IQUwQacrfeHznzc2gfaeb12qw6U3jzg"
                  libraries={libraries}
                >
                  <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={handlePlaceChanged}
                    fields={fields}
                    restrictions={restrictions}
                  >
                    <input
                      type="text"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                        errors.address && "border-rose-700"
                      }`}
                      placeholder="Av Las Condes 1234"
                      id="address"
                      {...register("address", { required: true })}
                    />
                  </Autocomplete>
                  <GoogleMap
                    onLoad={onLoadMap}
                    id="searchbox-example"
                    mapContainerStyle={mapContainerStyle}
                    onUnmount={onUnmount}
                    zoom={15}
                    className="mt-1"
                  >
                    <Marker position={markerPosition} />
                  </GoogleMap>
                </LoadScript>
                {errors.address && errors.address.type === "required" && (
                  <span className="text-pink-700">Este campo es requerido</span>
                )}
              </div>
              <div>
                <label
                  className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                  htmlFor="area"
                >
                  Comuna
                </label>
                <input
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                    errors.area && "border-pink-700"
                  }`}
                  placeholder="Santiago centro"
                  id="area"
                  {...register("area", { required: true })}
                />
                {errors.area && errors.area.type === "required" && (
                  <span className="text-pink-700">Este campo es requerido</span>
                )}
              </div>
            </form>

            <div className="flex items-center justify-between mt-6">
              <button
                type="button"
                className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                onClick={handleCancel}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={handleSubmit(onSubmit)}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal show={setShow} onHide={handleCancel} onEnter={onEnter} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Cliente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="login__content__form__body">
          <div className="row">
            <div className="col">
              <label htmlFor="name">Nombre</label>
              <input
                type="text"
                className={`form-control ${errors.name && "border-pink-700"}`}
                placeholder="Juanita Del Carmen"
                id="name"
                {...register("name", { required: true })}
              />
              {errors.name && errors.name.type === "required" && (
                <span className="text-danger">Este campo es requerido</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="rut">Rut</label>
              <input
                type="text"
                className={`form-control ${errors.rut && "is-invalid"}`}
                placeholder="12.345.678-9"
                id="rut"
                {...register("rut", { required: false })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="rut">Telefono</label>
              <input
                type="number"
                className={`form-control ${errors.phone && "is-invalid"}`}
                placeholder="5691234568"
                id="phone"
                {...register("phone", { required: true })}
              />
              {errors.phone && errors.phone.type === "required" && (
                <span className="text-danger">Este campo es requerido</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="email">Correo electrónico</label>
              <input
                type="text"
                className={`form-control ${errors.email && "is-invalid"}`}
                placeholder="juanitadc@gmail.com"
                id="email"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email && errors.email.type === "required" && (
                <span className="text-danger">Este campo es requerido</span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span className="text-danger">Formato inválido</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="address">Dirección</label>
              <LoadScript
                googleMapsApiKey="AIzaSyD0IQUwQacrfeHznzc2gfaeb12qw6U3jzg"
                libraries={libraries}
              >
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={handlePlaceChanged}
                  fields={fields}
                  restrictions={restrictions}
                >
                  <input
                    type="text"
                    className={`form-control ${errors.address && "is-invalid"}`}
                    placeholder="Av Las Condes 1234"
                    id="address"
                    {...register("address", { required: true })}
                  />
                </Autocomplete>
                <GoogleMap
                  onLoad={onLoadMap}
                  id="searchbox-example"
                  mapContainerStyle={mapContainerStyle}
                  zoom={15}
                  onUnmount={onUnmount}
                >
                  <Marker position={markerPosition} />
                </GoogleMap>
              </LoadScript>
              {errors.address && errors.address.type === "required" && (
                <span className="text-danger">Este campo es requerido</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="area">Comuna</label>
              <input
                type="text"
                className={`form-control ${errors.area && "is-invalid"}`}
                placeholder="Santiago centro"
                id="area"
                {...register("area", { required: true })}
              />
              {errors.area && errors.area.type === "required" && (
                <span className="text-danger">Este campo es requerido</span>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleCancel}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        >
          Guardar
        </button>
      </Modal.Footer>
    </Modal> */}
    </div>
  );
}

export default Customer;
