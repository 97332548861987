import { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Loading from '../components/loading/Loading';

function Authenticated({component: Component}) {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      if (user) {
        setIsAuthenticated(true);
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    isAuthenticated ? <Navigate to='/admin' /> : loading ? <Loading setShow={setLoading} /> : <Component />
  );
}

export default Authenticated;
