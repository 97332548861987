import "./SignIn.scss";
import Login from "../Login";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/compat/auth";
import "firebase/compat/firestore";

function SignIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    console.log(showLoading);
    setShowLoading(true);
    try {
      console.log("se inicia el trycatch de signin");
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password);
      if (result.user.emailVerified) {
        console.log("verificado correctamente");
        const account = await firebase
          .firestore()
          .collection("accounts")
          .doc(result.user.uid)
          .get();
        if (["admin", "company"].indexOf(account.data().role) !== -1) {
          console.log("pasa por el if para ver si es admin o company");
          const storage = account.data();
          localStorage.setItem("account", JSON.stringify(storage));
          navigate("/admin");
        } else {
          firebase.auth().signOut();
          alert("Tu email o contraseña son incorrectos");
        }
      } else {
        result.user.sendEmailVerification();
        firebase.auth().signOut();
        alert("Debes verificar tu correo electrónico y luego ingresar");
      }
    } catch (e) {
      console.log(e.message);
      let message = "Se ha producido un error, intenta nuevamente";
      if (
        [
          "auth/user-not-found",
          "auth/wrong-password",
          "auth/too-many-requests",
          "auth/user-disabled",
        ].indexOf(e.code) !== -1
      ) {
        message = "Tu email o contraseña son incorrectos";
      }
      alert(message);
    }
    setShowLoading(false);
  };

  return (
    <Login showLoading={showLoading}>
      <div class="bg-gray-100 flex justify-center items-center h-screen">
        <div class="bg-white p-8 rounded shadow-md w-full max-w-sm">
          <h1 class="text-2xl font-semibold mb-4">
            {" "}
            Ingresa la información requerida para iniciar sesión
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-600">
                Correo electrónico
              </label>
              <input
                type="text"
                className="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                placeholder="juanito.perez@gmail.com"
                id="email"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email && errors.email.type === "required" && (
                <span className="text-danger">Este campo es requerido</span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span className="text-danger">Formato inválido</span>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-600">
                Contraseña
              </label>
              <input
                type="password"
                className="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-500"
                placeholder="*************"
                id="password"
                {...register("password", { required: true })}
              />
              {errors.password && (
                <span className="text-danger">Este campo es requerido</span>
              )}
            </div>
            <div className="row mt-3">
              <div className="col">
                <button
                  type="submit"
                  class="w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
                >
                  Ingresar
                </button>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <a href="/login/reset-password">¿Olvidaste tu contraseña?</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Login>
  );
}

export default SignIn;
