export const compName = [];
export const cartItems = [];

export const exportCompName = (companyName) => {
  compName.push(companyName);
};

export const DeleteItems = async (id, setCartItems) => {
  const index = cartItems.findIndex((item) => item.id === id);
  if (index !== -1) {
    cartItems.splice(index, 1);
    setCartItems([...cartItems]);
  }
};

export const Cart = async (selectItem, quantity) => {
  if (cartItems.length > 0) {
    const index = cartItems.findIndex((item) => item.id === selectItem.id);
    if (index !== -1) {
      cartItems[index].cartQty = quantity;
    } else {
      cartItems.push({
        ...selectItem,
        cartQty: quantity,
      });
    }
  } else {
    cartItems.push({
      ...selectItem,
      cartQty: quantity,
    });
  }
};

export const CartPrice = () => {
  const subTotal = cartItems.reduce((acc, item) => {
    return acc + item.cartQty * item.price;
  }, 0);

  const iva = subTotal * 0.19;
  const total = subTotal + iva;
  if (subTotal === 0) {
    return { subTotal: 0, total: 0, iva: 0 };
  } else {
    return { subTotal, total, iva };
  }
};
