import "./OrderDetail.scss";
import Admin from "../Admin";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Moment from "moment";
import Swal from "sweetalert2";
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";

function OrderDetail() {
  const { id } = useParams();
  const [item, setItem] = useState({});
  const [product, setProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [qty, setQty] = useState(0);
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const account = JSON.parse(localStorage.getItem("account"));
  const companyId = account.access.at(0);

  const mapContainerStyle = {
    height: "300px",
    width: "100%",
  };

  useEffect(() => {
    setShowLoading(true);
    firebase
      .firestore()
      .collection("companies")
      .doc(companyId)
      .collection("orders")
      .doc(id)
      .onSnapshot((snap) => {
        setItem(snap.data());
        setShowLoading(false);
      });
  }, []);

  useEffect(() => {
    (() => {
      const products = firebase
        .firestore()
        .collection("companies")
        .doc(companyId)
        .collection("products");
      products.onSnapshot((snap) => {
        const options = snap.docs.map((doc) => {
          return {
            id: doc.id,
            name: doc.data().name,
            price: doc.data().price,
            stock: doc.data().stock,
          };
        });
        setOptions(options);
      });
    })();
  }, []);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }).format(number);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const addProduct = () => {
    if (qty > 0 && qty <= selectedProduct.stock) {
      console.log("addProduct", selectedProduct);
      const newProduct = {
        id: selectedProduct.id,
        name: selectedProduct.name,
        price: selectedProduct.price,
        qty: qty,
      };

      if (item.items) {
        console.log("entra en el if");
        if (!item.items.find((item) => item.id === newProduct.id)) {
          const newProducts = item.items;
          console.log(newProducts);
          newProducts.push(newProduct);
          const total = newProducts.reduce(
            (acc, item) => acc + item.qty * item.price,
            0
          );
          const iva = total * 0.19;
          const subtotal = total - iva;
          firebase
            .firestore()
            .collection("companies")
            .doc(companyId)
            .collection("orders")
            .doc(item.id)
            .update({
              items: newProducts,
              total,
              iva,
              subtotal,
            });
        } else {
          console.log("entra en el else del qty");
          const newProducts = item.items.map((item) => {
            if (item.id === newProduct.id) {
              return {
                ...item,
                qty: Number(item.qty) + Number(newProduct.qty),
              };
            } else {
              return item;
            }
          });
          const total = newProducts.reduce(
            (acc, item) => acc + item.qty * item.price,
            0
          );
          const iva = total * 0.19;
          const subtotal = total - iva;
          firebase
            .firestore()
            .collection("companies")
            .doc(companyId)
            .collection("orders")
            .doc(item.id)
            .update({
              items: newProducts,
              total,
              iva,
              subtotal,
            });
        }
      } else {
        const newProducts = [];
        newProducts.push(newProduct);
        const total = newProducts.reduce(
          (acc, item) => acc + item.qty * item.price,
          0
        );
        const iva = total * 0.19;
        const subtotal = total - iva;
        firebase
          .firestore()
          .collection("companies")
          .doc(companyId)
          .collection("orders")
          .doc(item.id)
          .update({
            items: newProducts,
            total,
            iva,
            subtotal,
          });
      }
      const newStock = selectedProduct.stock - qty;

      firebase
        .firestore()
        .collection("companies")
        .doc(companyId)
        .collection("products")
        .doc(selectedProduct.id)
        .update({
          stock: newStock,
        });
      setActive(false);
      setSelectedProduct({});
      setQty(0);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El stock no es valido",
      });
    }
  };

  const handleCancel = () => {
    setQty(0);
    setSelectedProduct({});
    setActive(false);
  };

  const handleQty = (e) => {
    if (e.target.id === "add") {
      if (qty < selectedProduct.stock) {
        setQty(qty + 1);
      }
    }
    if (e.target.id === "less") {
      if (qty > 0) {
        setQty(qty - 1);
      }
    }
  };

  const handleSelect = (value) => {
    console.log("handleSelect", value);
    if (value !== "null") {
    const product = options.find((option) => option.id === value);
    console.log("product", product);
    setSelectedProduct(product);
    }else if (value === "null") {
      console.log("entra en el else")
      setSelectedProduct({});
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debes seleccionar un producto",
      });
    }
  };

  const edit = (product) => {
    setProduct(product);
    toggleModal();
  };

  const deleteItem = (product) => {
    console.log("delete", product);
    Swal.fire({
      title: "¿Estás seguro de eliminar " + product.name + "?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const newProducts = item.items.filter((item) => item.id !== product.id);
        console.log("newProducts", newProducts);
        const total = newProducts.reduce(
          (acc, item) => acc + item.qty * item.price,
          0
        );
        const iva = total * 0.19;
        const subtotal = total - iva;
        await firebase
          .firestore()
          .collection("companies")
          .doc(companyId)
          .collection("orders")
          .doc(item.id)
          .update({
            items: newProducts,
            total,
            iva,
            subtotal,
          });
        Swal.fire(
          "¡Eliminado!",
          product.name + " ha sido eliminado.",
          "success"
        );
        const productDeleted = firebase
          .firestore()
          .collection("companies")
          .doc(companyId)
          .collection("products")
          .doc(product.id);
        productDeleted.get().then((doc) => {
          const newStock = doc.data().stock + Number(product.qty);
          productDeleted.update({
            stock: Number(newStock),
          });
        });
      }
    });
  };

  return (
    <Admin showLoading={showLoading}>
      {/*header: aqui va un boton para añadir producto junto a un boton de volver, el boton de añadir solo tiene que estar presente cuando la orden no este finalizada */}
      <header className="mt-3">
        <div className="flex justify-between items-center">
          <button
            className="bg-blue-200 py-2 px-3 rounded-full transition ease-in-out duration-200 hover:bg-blue-300 text-primary font-bold"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-arrow-left"></i> Volver
          </button>
          {item.status !== 500 && (
          <button
            className="bg-green-200 py-2 px-3 rounded-full transition ease-in-out duration-200 hover:bg-blue-300 text-primary font-bold"
            onClick={() => setActive(true)}
          >
            <i className="fa fa-plus"></i> Añadir producto
          </button>
          )}
        </div>
      </header>
      {/*body*/}
      {Object.keys(item).length > 0 && (
        <div className="flex flex-col w-full sm:flex-row mt-5">
          <div className="w-full sm:w-1/2">
          <div className="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
            <div className="flex items-center justify-between mb-4">
              <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
                Detalles de la orden
              </h5>
            </div>
            <div className="flow-root">
              <ul
                role="list"
                className="divide-y divide-gray-200 dark:divide-gray-700"
              >
                <li className="py-2 sm:py-2">
                  <div className="flex items-center space-x-2">
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        ID
                      </p>
                    </div>
                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {item.id.slice(0, 8)}
                    </div>
                  </div>
                </li>
                <li className="py-2 sm:py-2">
                  <div className="flex items-center space-x-2">
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        Numero de orden
                      </p>
                    </div>
                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {item.correlativeNumber}
                    </div>
                  </div>
                </li>
                <li className="py-2 sm:py-2">
                  <div className="flex items-center space-X-2">
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        Cliente
                      </p>
                    </div>
                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {item.customer.name}
                    </div>
                  </div>
                </li>
                <li className="py-2 sm:py-2">
                  <div className="flex items-center space-X-2">
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        Dirección
                      </p>
                    </div>
                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {item.customer.address}
                    </div>
                  </div>
                </li>
                <li className="py-2 sm:py-2">
                  <div className="flex items-center space-x-4">
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        Teléfono
                      </p>
                    </div>
                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {item.customer.phone}
                    </div>
                  </div>
                </li>
                <li className="py-2 pb-0 sm:py-2">
                  <div className="flex items-center space-x-4">
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        Fecha de creación
                      </p>
                    </div>
                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {item.createdAt
                        ? Moment(item.createdAt.toDate()).format("DD/MM/YYYY")
                        : null}
                    </div>
                  </div>
                </li>
                <li className="py-2 pb-0 sm:py-2">
                  <div className="flex items-center space-x-4">
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        Total de la orden
                      </p>
                    </div>
                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {formatNumber(item.total)}
                    </div>
                  </div>
                </li>
                <li className="py-2 pb-0 sm:py-2">
                  <div className="flex items-center space-x-4">
                    <div className="flex-1 min-w-0">
                    <LoadScript googleMapsApiKey="AIzaSyD0IQUwQacrfeHznzc2gfaeb12qw6U3jzg">
                    <GoogleMap
                      zoom={18}
                      mapContainerStyle={mapContainerStyle}
                      center={{
                        lat: item.customer.lat,
                        lng: item.customer.lng,
                      }}
                    >
                      <Marker
                        position={{
                          lat: item.customer.lat,
                          lng: item.customer.lng,
                        }}
                      />
                    </GoogleMap>
                  </LoadScript>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          </div>

          <div className="relative overflow-x-auto shadow-md w-full sm:rounded-lg h-fit sm:ml-5">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Nombre del producto
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Cantidad
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Precio
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Subtotal
                  </th>
                  <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {active === true && item.status !== 300 && (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <select
                        id="underline_select"
                        className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                        onChange={(e) => handleSelect(e.target.value)}
                      >
                        <option value="null">Selecciona un producto</option>
                        {options.map((items) => (
                          <option value={items.id} key={items.id}>
                            {items.name}
                          </option>
                        ))}
                      </select>
                    </th>
                    <td className="px-6 py-4">
                      {selectedProduct.stock ? (
                        <div className="flex items-center">
                          <button
                            id="less"
                            onClick={(e) => handleQty(e)}
                            className="bg-gray-100 dark:bg-slate-700 rounded-full p-2 focus:outline-none"
                          >
                            -
                          </button>
                          <span className="mx-2 text-gray-700 dark:text-gray-400">
                            {qty}
                          </span>
                          <button
                            id="add"
                            onClick={(e) => handleQty(e)}
                            className="bg-gray-100 dark:bg-slate-700 rounded-full p-2 focus:outline-none"
                          >
                            +
                          </button>
                        </div>
                      ) : (
                        <span className="text-danger">
                          Sin stock
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4">
                      {selectedProduct.price
                        ? formatNumber(selectedProduct.price)
                        : 0}
                    </td>
                    <td className="px-6 py-4">
                      {selectedProduct.stock
                        ? formatNumber(selectedProduct.price * qty)
                        : 0}
                    </td>
                    <td className="px-6 py-4 text-right">
                      <button
                        onClick={() => addProduct()}
                        className="inline-flex mr-2 items-center p-1 w-10 justify-center text-base font-medium text-white bg-blue-600 border border-transparent rounded-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:focus:ring-offset-gray-800"
                      >
                        <i className="fas fa-check"></i>
                      </button>
                      <button
                        onClick={() => handleCancel()}
                        className="inline-flex items-center p-1 w-10 justify-center text-base font-medium text-white bg-red-600 border border-transparent rounded-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 dark:focus:ring-offset-gray-800"
                      >
                        <i className="fas fa-times"></i>
                      </button>

                    </td>
                  </tr>
                )}

                {item.items &&
                  item.items.map((items) => (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={items.id}>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {items.name}
                      </th>
                      <td className="px-6 py-4">{items.price}</td>
                      <td className="px-6 py-4">{items.qty}</td>
                      <td className="px-6 py-4">
                        {formatNumber(items.qty * items.price)}
                      </td>
                      
                      <td className="px-6 py-4 text-right">
                        {item.status !== 500 && (
                        <button
                          onClick={() => deleteItem(items)}
                          className="font-medium text-red-600 dark:text-red-500 hover:underline cursor-pointer"
                        >
                          Eliminar
                        </button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {/*footer*/}

      {/*<div className="admin__content__header">
        <div className="order__header__navbar">
          <div className="order__header__navbar__item">
            <a className="link" onClick={() => navigate(-1)}>
              <i className="fa fa-arrow-left"></i> Volver
            </a>
          </div>

          <div className="order__header__navbar__item">
            {item && item.data().status !== 300 && (
              <button
                className="btn btn-primary"
                onClick={() => setActive(true)}
              >
                Añadir Producto
              </button>
            )}
          </div>
        </div>
      </div>
      {item && (
        <div className="admin__content__body">
          <ProductDetail
            setShow={showModal}
            toggleModal={toggleModal}
            showLoading={setShowLoading}
            values={product}
            setValues={setProduct}
            order={item}
          />
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <h3>Orden</h3>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td width="50%">ID</td>
                    <td>{item.data().id}</td>
                  </tr>
                  <tr>
                    <td>Numero de orden</td>
                    <td>{item.data().correlativeNumber}</td>
                  </tr>
                  <tr>
                    <td>Cliente</td>
                    <td>{item.data().customer.name}</td>
                  </tr>
                  <tr>
                    <td>Dirección</td>
                    <td>{item.data().customer.address}</td>
                  </tr>
                  <tr>
                    <td>Telefono</td>
                    <td>{item.data().customer.phone}</td>
                  </tr>
                  <tr>
                    <td>Fecha</td>
                    <td>
                      {item.createdAt
                        ? Moment(item.data().createdAt.toDate()).format(
                            "DD/MM/YYYY HH:mm"
                          )
                        : null}
                    </td>
                  </tr>
                  <tr>
                    <td>Subtotal</td>
                    <td>{formatNumber(item.data().subtotal)}</td>
                  </tr>
                  <tr>
                    <td>IVA</td>
                    <td>{formatNumber(item.data().iva)}</td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td>{formatNumber(item.data().total)}</td>
                  </tr>
                </tbody>
              </table>
              <div>
                <h3>Mapa</h3>
                <div className="map">
                  <LoadScript googleMapsApiKey="AIzaSyD0IQUwQacrfeHznzc2gfaeb12qw6U3jzg">
                    <GoogleMap
                      zoom={18}
                      mapContainerStyle={mapContainerStyle}
                      center={{
                        lat: item.data().customer.lat,
                        lng: item.data().customer.lng,
                      }}
                    >
                      <Marker
                        position={{
                          lat: item.data().customer.lat,
                          lng: item.data().customer.lng,
                        }}
                      />
                    </GoogleMap>
                  </LoadScript>
                </div>
              </div>
            </div>

            <div className="col-md-8 col-sm-12">
              <h3>Productos</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Producto</th>
                    <th className="text-end">Precio</th>
                    <th className="text-end">Cantidad</th>
                    <th className="text-end">Subtotal</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {active === true && item.data().status !== 300 && (
                    <tr>
                      <td>
                        <Select
                          id="products"
                          options={options}
                          placeholder="Seleccione un producto"
                          onChange={(items) => setSelectedProduct(items)}
                        ></Select>
                      </td>
                      <td className="text-end">
                        {selectedProduct.price
                          ? formatNumber(selectedProduct.price)
                          : 0}
                      </td>
                      <td className="text-end">
                        {selectedProduct.stock &&
                        selectedProduct.stock >= qty ? (
                          <input
                            type="number"
                            value={qty}
                            className="form-control-small"
                            onChange={(e) => (
                              e.preventDefault(), setQty(e.target.value)
                            )}
                          />
                        ) : selectedProduct.stock === 0 ? (
                          <div className="text-danger">Sin stock</div>
                        ) : (
                          selectedProduct.stock < qty && (
                            <div>
                              <input
                                type="number"
                                value={qty}
                                className="form-control-small"
                                onChange={(e) => (
                                  console.log(qty), setQty(e.target.value)
                                )}
                              />
                              <span className="text-danger">
                                No hay suficiente stock
                              </span>
                            </div>
                          )
                        )}
                      </td>
                      <td className="text-end">
                        {selectedProduct.price
                          ? formatNumber(selectedProduct.price * qty)
                          : 0}
                      </td>
                      <td>
                        <button
                          className="navbar__btn__green"
                          onClick={addProduct}
                        >
                          <i className="fa fa-check" />
                        </button>
                        <button
                          className="navbar__btn__red"
                          onClick={handleCancel}
                        >
                          <i className="fa fa-times" />
                        </button>
                      </td>
                    </tr>
                  )}

                  {item.data().items &&
                    item.data().items.map((items) => (
                      <tr key={items.id}>
                        <td>{items.name}</td>
                        <td className="text-end">
                          {formatNumber(items.price)}
                        </td>
                        <td className="text-end">{items.qty}</td>
                        <td className="text-end">
                          {formatNumber(items.qty * items.price)}
                        </td>
                        {item.data().status !== 500 && (
                          <td>
                            {/* <a className="link">
                          <i
                            className="fa fa-edit"
                            onClick={() => edit(item)}
                          ></i>
                        </a> 

                            <a className="link">
                              <i
                                className="fa fa-trash"
                                onClick={() => deleteItem(items)}
                              ></i>
                            </a>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) */}
    </Admin>
  );
}

export default OrderDetail;
