import "./Dashboard.scss";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Admin from "../Admin";

function Dashboard() {
  const navigate = useNavigate();
  const account = JSON.parse(localStorage.getItem("account"));
  const companyId = account.access.at(0);

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = `https://achedoso.cl/tienda/${companyId}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    Swal.fire({
      icon: "success",
      title: "Link copiado",
      text: "El link de tu tienda online ha sido copiado al portapapeles",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <Admin>
      <h1 className="text-3xl mt-5 text-center sm:bg-blue-500 sm:w-fit sm:pl-10 sm:-ml-10 sm:pr-5 sm:rounded-full sm:py-2 font-bold sm:text-white dark:text-gray-100 sm:mb-5 ">
        Dashboard
      </h1>
      <div className="flex flex-col sm:flex-row sm:flex-wrap items-center mt-2.5">
        <div className="my-5 sm:mx-2.5">
          <div className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Catalogo Online
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              En tu catálogo online tus clientes podran seleccionar los
              productos que desean y encargar el pedido ellos mismos. Tu solo
              debes preocuparte hacer el cobro y de entregar el pedido.
            </p>
            <div className="flex justify-between">
              <button
                onClick={copyToClipboard}
                className="inline-flex items-center mt-5 px-3 py-2 text-sm font-medium text-center text-white bg-blue-500 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Compartir Link
              </button>
              <button
                onClick={() => navigate(`/tienda/${companyId}`)}
                className="inline-flex items-center mt-5 px-3 py-2 text-sm font-medium text-center text-white bg-blue-500 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Ver tu tienda
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="admin__content__header">
        <div className="admin__content__header__title">Dashboard</div>
      </div>
      <div className="admin__content__body">
        <div className="dashboard">
          <div className="linkBox">
            <div className="linkBox-title">Enlace de la tienda:</div>
            <div onClick={()=>navigate(
              `/tienda/${companyId}`
            )} className="linkBox-link">
            https://achedoso.cl/tienda/{companyId}
            </div>
          </div>
          <img alt="" src="/assets/img/logotype_h2o_sys.png" />
        </div>
      </div> */}
    </Admin>
  );
}

export default Dashboard;
