import "./Accounts.scss";
import Admin from "../Admin";
import React, { useState, useEffect } from "react";
import { Table } from "ka-table";
import { DataType } from "ka-table/enums";
import Account from "../../../modals/admin/account/Account";
import Dropdown from "react-bootstrap/Dropdown";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

function Accounts() {
  const [showLoading, setShowLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const tableProps = {
    columns: [
      { key: "name", title: "Nombre", dataType: DataType.String },
      { key: "email", title: "Correo electrónico", dataType: DataType.String },
      { key: "role", title: "Rol", dataType: DataType.String },
      { key: "actions", title: "", dataType: DataType.String, width: 100 },
    ],
    rowKeyField: "id",
    //sortingMode: SortingMode.Single
  };
  const [data, setData] = useState([]);
  const [item, setItem] = useState({});
  const [restaurants, setRestaurants] = useState({});
  const [suppliers, setSuppliers] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      setShowLoading(true);
      const res = await firebase.firestore().collection("restaurants").get();
      setRestaurants(
        res.docs.map((doc) => ({
          value: doc.id,
          label: doc.data().name,
        }))
      );
      const sup = await firebase.firestore().collection("suppliers").get();
      setSuppliers(
        sup.docs.map((doc) => ({
          value: doc.id,
          label: doc.data().name,
        }))
      );
      firebase
        .firestore()
        .collection("accounts")
        .where("role", "in", ["supplier", "restaurant"])
        .onSnapshot((snap) => {
          if (!snap.empty) {
            const data = snap.docs.map((item, index) => ({
              name: item.data().name,
              email: item.data().email,
              role: item.data().role,
              access: item.data().access || [],
              id: item.id,
            }));
            console.log("data", data);
            setData(data);
            setLoaded(true);
            setShowLoading(false);
          }
        });
    })();
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const edit = (item) => {
    setItem(item);
    toggleModal();
  };

  return (
    <Admin showLoading={showLoading}>
      <div className="admin__content__header">
        <div className="admin__content__header__title">Cuentas</div>
        <div className="admin__content__header__actions">
          <button
            type="button"
            className="btn btn-primary"
            onClick={toggleModal}
          >
            Agregar cuenta
          </button>
        </div>
      </div>
      {loaded && (
        <div className="admin__content__body">
          <Account
            setShow={showModal}
            toggleModal={toggleModal}
            showLoading={setShowLoading}
            values={item}
            setValues={setItem}
            restaurants={restaurants}
            suppliers={suppliers}
          />
          <Table
            {...tableProps}
            childComponents={{
              cellText: {
                content: (props) => {
                  if (props.column.key === "actions") {
                    return (
                      <>
                        <Dropdown className="actions-dropdown">
                          <Dropdown.Toggle id="dropdown-basic">
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => edit(props.rowData)}>
                              Editar
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    );
                  }
                },
              },
            }}
            data={data}
          />
        </div>
      )}
    </Admin>
  );
}

export default Accounts;
