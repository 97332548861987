import "./Product.scss";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import Swal from "sweetalert2";
import { Switch } from "antd";

function Product({ setShow, toggleModal, showLoading, values, setValues }) {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const account = JSON.parse(localStorage.getItem("account"));
  const companyId = account.access.at(0);

  const [active, setActive] = useState(true);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [images, setImages] = useState([]);
  const [valuesId, setValuesId] = useState();
  const [isConfirmationShown, setIsConfirmationShown] = useState(false);

  const defaultValues = {
    name: "",
    description: "",
    stock: "",
    price: "",
    brand: "",
    sku: "",
  };

  useEffect(() => {
    if (values.id) {
      console.log(values.id);
      console.log("hay valores");
      reset(values);
    } else {
      console.log("no hay valores");
      reset(defaultValues);
    }
  }, [values, reset]);

  const onActive = (active) => {
    setActive(active);
  };

  const cancelButton = () => {
    reset(values);
    setValuesId(null);
    setFile(null);
    setPreview(null);
    setImages([]);
    toggleModal();
  };

  const handleDeleteImg = async (img) => {
    console.log(valuesId);
    try {
      console.log(img.id);
      const { value: confirmed } = await Swal.fire({
        title: "¿Estás seguro?",
        text: "No podrás revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
      });

      if (confirmed) {
        const storageRef = firebase
          .storage()
          .ref(`/companies/${companyId}/products/${valuesId}/`);
        await storageRef.child(img.name).delete();
        const productRef = firebase
          .firestore()
          .collection("companies")
          .doc(companyId)
          .collection("products")
          .doc(valuesId);
        await productRef.update({
          images: firebase.firestore.FieldValue.arrayRemove({
            id: img.id,
            name: img.name,
            url: img.url,
          }),
        });
        const newImages = images.filter((i) => i.id !== img.id);
        setImages(newImages);
        Swal.fire("Eliminado", "La imagen ha sido eliminada", "success");
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Error", "Ha ocurrido un error al eliminar la imagen", "error");
    }
  };

  const confirmationHandler = (e) => {
    if (file !== null && file.length > 0 && !isConfirmationShown) {
      e.preventDefault();
      setIsConfirmationShown(true);
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Ten en cuenta que si vuelves a seleccionar esta opción para añadir más imágenes, las anteriores se eliminarán y se reemplazarán por las nuevas",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, agregar",
      }).then((result) => {
        setIsConfirmationShown(false);
        if (result.isConfirmed) {
          e.target.value = null;
          setPreview(null);
          setFile(null);
          setImages([]);
          e.target.click();
        }
      });
    }
  };

  const onFileChange = async (e) => {
    const files = e.target.files;
    const filesType = ["image/png", "image/jpeg", "image/jpg"];
    if (files.length > 0 && files.length <= 5) {
      const filteredFiles = Array.from(files).filter((f) =>
        filesType.includes(f.type)
      );
      if (filteredFiles.length !== files.length) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Solo puedes subir imágenes en formato png, jpg o jpeg",
        });
        e.target.value = null;
        setPreview([]);
        setFile([]);
        return;
      } else {
        const fileArray = Array.from(files);
        const previews = [];
        for (let i = 0; i < fileArray.length; i++) {
          const reader = new FileReader();
          reader.onloadend = () => {
            previews.push(reader.result);
            if (previews.length === fileArray.length) {
              setPreview(previews);
            }
          };
          reader.readAsDataURL(fileArray[i]);
        }
        setFile(fileArray);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Solo puedes subir hasta 5 imágenes",
      });
      e.target.value = null;
      setPreview([]);
      setFile([]);
    }
  };

  const onSubmit = async (data) => {
    showLoading(true);
    try {
      if (!data.id) {
        data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
        data.createdBy = firebase.auth().currentUser.uid;
        data.deletedAt = null;
        data.deletedBy = null;
      }
      if (data.id) {
        data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
        data.updatedBy = firebase.auth().currentUser.uid;
        data.stock = active === true ? Number(data.stock) : null;
      }
      const doc = firebase
        .firestore()
        .collection("companies")
        .doc(companyId)
        .collection("products")
        .doc(data.id);
      data.id = doc.id;
      if (file && Array.isArray(file)) {
        try {
          const storageRef = firebase
            .storage()
            .ref("/companies/" + companyId + "/products/" + data.id + "/");
          const promises = file.map(async (f) => {
            const stringDate = new Date().toISOString();
            const id = stringDate.replace(/[^a-zA-Z0-9]/g, "");
            const fileRef = storageRef.child(f.name);
            await fileRef.put(f);
            const url = await fileRef.getDownloadURL();
            return { name: f.name, id: id + f.name, url };
          });
          const images = await Promise.all(promises);
          data.images = images.length
            ? firebase.firestore.FieldValue.arrayUnion(...images)
            : null;
        } catch (err) {
          console.error(err);
          data.images = null;
        }
      } else {
        data.images = null;
      }
      await doc.set(data, { merge: true });
      Swal.fire({
        icon: "success",
        title: "Producto guardado",
        showConfirmButton: false,
        timer: 1500,
      });
      reset(values);
      setFile(null);
      setPreview(null);
      toggleModal();
    } catch (e) {
      alert(e.message);
    }
    showLoading(false);
  };

  return (
    <div>
      <div
        id="authentication-modal"
        aria-hidden="true"
        className={`fixed top-0 left-0 right-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50 w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen ${
          setShow ? "block" : "hidden"
        }`}
      >
        <div className="relative w-full max-w-lg max-h-full">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={cancelButton}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="px-6 py-6 lg:px-8">
              <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                Ingrese el producto
              </h3>
              {/* <!-- carousel --> */}
              {/* no entiendo como funciona el carousel de flowbite ayuda! */}
              {/* <!-- Form --> */}
              <form>
                <div className="grid gap-2">
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Nombre
                    </label>
                    <input
                      type="text"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        errors.name && "border-red-500"
                      }`}
                      placeholder="Ingrese el nombre del producto"
                      id="name"
                      {...register("name", { required: true })}
                    />
                    {errors.name && errors.name.type === "required" && (
                      <span className="text-red-500">
                        Este campo es requerido
                      </span>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="price"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Precio
                    </label>
                    <input
                      type="number"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        errors.price && "is-invalid"
                      }`}
                      placeholder="Ingrese el precio ej: 1000"
                      id="price"
                      {...register("price", {
                        required: true,
                        pattern: /^[0-9]+$/,
                        validate: (value) => value > 0,
                      })}
                    />
                    {errors.price && errors.price.type === "required" && (
                      <span className="text-red-500">
                        Este campo es requerido
                      </span>
                    )}
                    {errors.price && errors.price.type === "pattern" && (
                      <span className="text-red-500">Formato inválido</span>
                    )}
                    {errors.price && errors.price.type === "validate" && (
                      <span className="text-red-500">
                        El valor debe ser mayor a 0
                      </span>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="sku"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      SKU
                    </label>
                    <input
                      type="text"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        errors.sku && "is-invalid"
                      }`}
                      placeholder="Ingrese el codigo del producto"
                      id="sku"
                      {...register("sku", { required: true })}
                    />
                    {errors.sku && errors.sku.type === "required" && (
                      <span className="text-red-500">
                        Este campo es requerido
                      </span>
                    )}
                  </div>
                  {active === true ? (
                    <div>
                      <label
                        htmlFor="stock"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Stock
                      </label>
                      <input
                        type="number"
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                          errors.stock && "border-red-500"
                        }`}
                        placeholder="ingrese stock"
                        id="stock"
                        {...register("stock", {
                          required: false,
                          pattern: /[0-9]/,
                          validate: (value) => value && value >= 0,
                        })}
                      />
                      {errors.stock && errors.stock.type === "required" && (
                        <span className="text-red-500">
                          Este campo es requerido
                        </span>
                      )}
                      {errors.stock && errors.stock.type === "pattern" && (
                        <span className="text-red-500">Formato inválido</span>
                      )}
                    </div>
                  ) : (
                    <div>
                      <label
                        htmlFor="stock"
                        className="block mb-2 text-sm font-medium text-gray-500 dark:text-white"
                      >
                        Stock
                      </label>
                      <input
                        type="number"
                        className={`bg-gray-100 border border-gray-300 text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                          errors.stock && "border-red-500"
                        }`}
                        placeholder="no aplica"
                        disabled
                      />
                    </div>
                  )}
                  <div>
                    <label
                      htmlFor="brand"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Marca
                    </label>
                    <input
                      type="text"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        errors.brand && "border-red-500"
                      }`}
                      placeholder="Ingresa la marca"
                      id="brand"
                      {...register("brand", { required: false })}
                    />
                    {errors.brand && errors.brand.type === "required" && (
                      <span className="text-red-500">
                        Este campo es requerido
                      </span>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Descripción
                    </label>
                    <textarea
                      type="text"
                      rows="4"
                      placeholder="Descripción breve..."
                      className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        errors.description && "ring-2 ring-red-500"
                      }`}
                      id="description"
                      style={{ resize: "none" }}
                      {...register("description", { required: false })}
                    />
                    {errors.description &&
                      errors.description.type === "required" && (
                        <span className="text-red-500">
                          Este campo es requerido
                        </span>
                      )}
                  </div>
                  <div className="flex mt-5 justify-between items-center">
                    {/* <div className="flex items-center">
                      <label
                        htmlFor="active"
                        className="text-sm font-medium text-gray-900 dark:text-white"
                      >
                        ¿Permite uso de stock?
                      </label> 
                      <Switch
                        onChange={onActive}
                        style={{ backgroundColor: active && values.stock !== null ? "#1677ff" : "gray" }}
                        checkedChildren="Si"
                        unCheckedChildren="No"
                        className="ml-2"
                      /> 
                    </div>*/}
                    <div>
                      <label
                        htmlFor="image"
                        className={
                          file !== null
                            ? "focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                            : "focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900"
                        }
                      >
                        {images.length > 0 ? (
                          <>
                            {file !== null && file.length > 0
                              ? file.length +
                                (file.length > 1
                                  ? " nuevas imagenes"
                                  : " nueva imagen")
                              : "¿Agregar imagenes?"}
                          </>
                        ) : (
                          <>
                            {file !== null && file.length > 0
                              ? "Total: " +
                                file.length +
                                (file.length > 1 ? " imagenes" : " imagen")
                              : "Seleccionar imagenes"}
                          </>
                        )}
                      </label>
                      <input
                        type="file"
                        className=""
                        id="image"
                        onChange={onFileChange}
                        onClick={confirmationHandler}
                        accept="image/jpeg, image/png, image/jpg"
                        multiple
                        hidden
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div>
                <div>
                  <button
                    type="button"
                    className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 mt-5"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal show={setShow} onHide={cancelButton} onEnter={onEnter} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="carousel__style">
          {file && preview ? (
            <Carousel>
              {preview &&
                preview.map((img) => (
                  <Carousel.Item key={img}>
                    <img src={img} alt="preview" />
                    <Carousel.Caption>
                      <h3>Preview</h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                ))}
            </Carousel>
          ) : (
            <Carousel>
              {images &&
                images.map((img) => (
                  <Carousel.Item key={img.id}>
                    <img src={img.url} alt="First slide" />
                    <Carousel.Caption>
                      <button
                        className="img-button"
                        onClick={() => handleDeleteImg(img)}
                      >
                        Borrar imagen
                      </button>
                    </Carousel.Caption>
                  </Carousel.Item>
                ))}
            </Carousel>
          )}
        </div>

        <form className="login__content__form__body">
          <div className="row">
            <div className="col">
              <label htmlFor="name">Nombre</label>
              <input
                type="text"
                className={`form-control ${errors.name && "border-red-500"}`}
                placeholder="Dispensador de agua, Stardew"
                id="name"
                {...register("name", { required: true })}
              />
              {errors.name && errors.name.type === "required" && (
                <span className="text-red-500">Este campo es requerido</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="price">Precio</label>
              <input
                type="number"
                className={`form-control ${errors.price && "is-invalid"}`}
                placeholder="80000"
                id="price"
                {...register("price", {
                  required: true,
                  pattern: /^[0-9]+$/,
                  validate: (value) => value > 0,
                })}
              />
              {errors.price && errors.price.type === "required" && (
                <span className="text-red-500">Este campo es requerido</span>
              )}
              {errors.price && errors.price.type === "pattern" && (
                <span className="text-red-500">Formato inválido</span>
              )}
              {errors.price && errors.price.type === "validate" && (
                <span className="text-red-500">El valor debe ser mayor a 0</span>
              )}
            </div>
            <div className="col">
              <label htmlFor="sku">SKU</label>
              <input
                type="text"
                className={`form-control ${errors.sku && "is-invalid"}`}
                placeholder="a123b4"
                id="sku"
                {...register("sku", { required: true })}
              />
              {errors.sku && errors.sku.type === "required" && (
                <span className="text-red-500">Este campo es requerido</span>
              )}
            </div>
            {active === true && (
              <div className="col">
                <label htmlFor="stock">Stock</label>
                <input
                  type="number"
                  className={`form-control ${errors.stock && "is-invalid"}`}
                  placeholder="10"
                  id="stock"
                  {...register("stock", {
                    required: false,
                    pattern: /[0-9]/,
                    validate: (value) => value && value >= 0,
                  })}
                />
                {errors.stock && errors.stock.type === "required" && (
                  <span className="text-red-500">Este campo es requerido</span>
                )}
                {errors.stock && errors.stock.type === "pattern" && (
                  <span className="text-red-500">Formato inválido</span>
                )}
              </div>
            )}
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="description">Descripción</label>
              <input
                type="text"
                className={`form-control ${errors.description && "is-invalid"}`}
                placeholder="Stardew dispensador de agua fria caliente"
                id="description"
                {...register("description", { required: false })}
              />
              {errors.description && errors.description.type === "required" && (
                <span className="text-red-500">Este campo es requerido</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="brand">Marca</label>
              <input
                type="text"
                className={`form-control ${errors.brand && "is-invalid"}`}
                placeholder="Stardew"
                id="brand"
                {...register("brand", { required: false })}
              />
              {errors.brand && errors.brand.type === "required" && (
                <span className="text-red-500">Este campo es requerido</span>
              )}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col">
              <div className="form-check checkbox-form">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="active"
                  checked={active}
                  onChange={onActive}
                />
                <label className="form-check-label" htmlFor="active">
                  ¿Permite uso de stock?
                </label>
              </div>
            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                  <label
                    htmlFor="image"
                    className={
                      file !== null
                        ? "btn file__btn__only__green"
                        : "btn file__btn__only__yellow"
                    }
                  >
                    {images.length > 0 ? (
                      <>
                        {file !== null && file.length > 0
                          ? "Haz seleccionado " +
                            file.length +
                            (file.length > 1
                              ? " nuevas imagenes"
                              : " nueva imagen")
                          : "¿Agregar imagenes?"}
                      </>
                    ) : (
                      <>
                        {file !== null && file.length > 0
                          ? " Haz seleccionado " +
                            file.length +
                            (file.length > 1 ? " imagenes" : " imagen")
                          : "Seleccionar imagenes"}
                      </>
                    )}
                  </label>
                  <input
                    type="file"
                    className="form-control-file"
                    id="image"
                    onChange={onFileChange}
                    onClick={confirmationHandler}
                    accept="image/jpeg, image/png, image/jpg"
                    multiple
                    hidden
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={cancelButton}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
        >
          Guardar
        </button>
      </Modal.Footer>
    </Modal> */}
    </div>
  );
}

export default Product;
