import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./pages/login/signin/SignIn";
import ResetPassword from "./pages/login/reset-password/ResetPassword";
import Dashboard from "./pages/admin/dashboard/Dashboard";
import Accounts from "./pages/admin/accounts/Accounts";
import Customers from "./pages/admin/customers/Customers";
import Orders from "./pages/admin/orders/Orders";
import OrderDetail from "./pages/admin/orderdetail/OrderDetail";
import Products from "./pages/admin/products/Products";
import Authenticated from './guards/Authenticated';
import RedirectIfAuthenticated from './guards/RedirectIfAuthenticated';
import Delivery from './pages/admin/delivery/Delivery';
import MapRoutes from './pages/admin/maproutes/MapRoutes';
import Carriers from './pages/admin/carriers/Carriers';
import Shopping from './pages/customer/shopping/Shopping';
import Checkout from './pages/customer/checkout/Checkout';
import OrderReady from './pages/customer/order/OrderReady';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/tienda/:companyId" element={<Shopping />}/>
        <Route path="/carro/:companyId" element={<Checkout/>}/>
        <Route path="/customer/order" element={<OrderReady/>}/>
        <Route path="/login" element={<Authenticated component={SignIn} />} />
        <Route path="/login/reset-password" element={<Authenticated component={ResetPassword} />} />
        <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
        <Route path="/admin/dashboard" element={<RedirectIfAuthenticated component={Dashboard} />} />
        <Route path="/admin/accounts" element={<RedirectIfAuthenticated component={Accounts} />} />
        <Route path="/admin/customers" element={<RedirectIfAuthenticated component={Customers} />} />
        <Route path="/admin/delivery" element={<RedirectIfAuthenticated component={Delivery} />} />
        <Route path="/admin/orders" element={<RedirectIfAuthenticated component={Orders} />} />
        <Route path="/admin/orderdetail/:id" element={<RedirectIfAuthenticated component={OrderDetail} />} />
        <Route path="/admin/products" element={<RedirectIfAuthenticated component={Products} />} />
        <Route path="/admin/routes" element={<RedirectIfAuthenticated component={MapRoutes} />} />
        <Route path="/admin/carriers" element={<RedirectIfAuthenticated component={Carriers} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
