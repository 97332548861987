import { useState, useEffect, useMemo } from "react";
import Admin from "../Admin";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./MapRoutes.scss";
import { ConfigProvider } from "antd";
import "dayjs/locale/es";
import locale from "antd/locale/es_ES";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function MapRoutes() {
  const navigate = useNavigate();
  const account = JSON.parse(localStorage.getItem("account"));
  const companyId = account.access.at(0);
  const [markerList, setMarkerList] = useState([]);
  const [companyList, setCompanyList] = useState({});
  const [delivery, setDelivery] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [deliveryStatuses, setDeliveryStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [orders, setOrders] = useState([]);
  const [center, setCenter] = useState({
    lat: -33.460464,
    lng: -70.656868,
  });
  const [map, setMap] = useState(null);

  useEffect(() => {
    (async () => {
      const config = await firebase
        .firestore()
        .collection("config")
        .doc("deliveryStatuses")
        .get();
      if (config.exists) {
        const data = config.data();
        if (data && data.list) {
          setDeliveryStatuses(data.list);
        } else {
          console.log(
            "La propiedad 'list' no está definida en los datos devueltos por la consulta a Firebase"
          );
        }
      } else {
        console.log(
          "No se encontró ningún documento con el ID 'deliveryStatuses' en la colección 'config'"
        );
      }
    })();
  }, []);

  useEffect(() => {
    firebase
      .firestore()
      .collection("carriers")
      .where("deletedAt", "==", null)
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        setCarriers(docs);
      });

    firebase
      .firestore()
      .collection("companies")
      .doc(companyId)
      .collection("orders")
      .where("deletedAt", "==", null)
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        setOrders(docs);
      });

    firebase
      .firestore()
      .collection("companies")
      .doc(companyId)
      .collection("delivery")
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        const positions = docs.map((doc) => {
          let color =
            doc.deliveryStatus === 0
              ? "lightgrey"
              : doc.deliveryStatus === 1
              ? "yellow"
              : doc.deliveryStatus === 2
              ? "lightblue"
              : doc.deliveryStatus === 3 && "lightgreen";
          return {
            id: doc.id,
            carrierId: doc.carrierId,
            deliveryDate: doc.deliveryDate,
            status: doc.deliveryStatus,
            position: doc.position,
            icon: {
              path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
              fillColor: color,
              fillOpacity: 1,
              strokeColor: "#000",
              strokeWeight: 2,
              scale: 0.7,
            },
          };
        });
        setDelivery(docs);
        setMarkerList(positions);
      });

    firebase
      .firestore()
      .collection("companies")
      .where("id", "==", companyId)
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        setCompanyList(...docs);
      });
  }, []);

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(dayjs().format("YYYY-MM-DD").toString());
    }
  }, [selectedDate]);

  const onLoadMap = (map) => {
    setMap(map);
    map.setZoom(12.3);
  };

  const filterMarkerList = useMemo(() => {
    let filteredMarkers = markerList;
    if (selectedCarrier) {
      filteredMarkers = filteredMarkers.filter(
        (marker) => marker.carrierId === selectedCarrier
      );
    }
    if (selectedDate) {
      filteredMarkers = filteredMarkers.filter(
        (marker) => marker.deliveryDate === selectedDate
      );
      if (filteredMarkers.length === 0) {
        if (map) {
          map.setZoom(12.3);
        }
      }
      if (filteredMarkers.length > 0) {
        if (filteredMarkers.length === 1) {
          map.panTo(filteredMarkers[0].position);
          map.setZoom(15);
          //open the infowindow of react-google-maps/api
          setSelectedMarker(filteredMarkers[0].id);
        } else if (filteredMarkers.length > 1) {
          map.panTo(center);
          map.setZoom(11.3);
        }
      }
    }
    if (selectedStatus) {
      filteredMarkers = filteredMarkers.filter(
        (marker) => marker.status === selectedStatus
      );
    }
    return filteredMarkers;
  }, [markerList, selectedCarrier, selectedDate, selectedStatus]);

  const datePicker = (date, dateString) => {
    setSelectedDate(dateString);
  };

  const onChangeCarrier = (carrier) => {
    setSelectedCarrier(carrier.value);
  };

  const onChangeStatus = (status) => {
    setSelectedStatus(status.value);
  };

  const onMarkerSelect = (marker) => {
    setSelectedMarker(marker.id);
    map.panTo(marker.position);
  };

  const editCarrier = () => {
    console.log("edit");
    navigate("/admin/delivery");
  };
  const mapStyles = {
    height: "90vh",
    width: "100%",
  };

  const googleMapsApiKey = "AIzaSyD0IQUwQacrfeHznzc2gfaeb12qw6U3jzg";

  return (
    <Admin>
      <div className="admin__content__body">
        <div className="cont">
          <div className="content-window">
            <div className="title">Mapa de rutas</div>
            <br />
            <div>Filtrar por:</div>
            <br />
            <div>Fecha:</div>
            <ConfigProvider locale={locale}>
              <DatePicker
                className="date-style"
                placeholder="Selecciona una fecha"
                format="DD/MM/YYYY"
                onChange={datePicker}
                defaultValue={dayjs()}
              />
            </ConfigProvider>
            <br />
            <br />
            {account.role === "admin" ||
              (account.role === "delivery" && (
                <>
                  <div>Compañia:</div>
                  <Select placeholder="Compañia" className="date-style" />
                  <br />
                </>
              ))}
            <div>Repartidores asignados:</div>
            <Select
              placeholder="Repartidores"
              className="date-style"
              isClearable
              classNamePrefix="Selecciona repartidor"
              options={carriers.map((carrier) => {
                return { value: carrier.id, label: carrier.name };
              })}
              onChange={onChangeCarrier}
            />
            <br />
            <div>Estado</div>
            <Select
              placeholder={"Estado"}
              className="basic-single"
              isClearable
              options={deliveryStatuses.map((status) => {
                return { value: status.code, label: status.name };
              })}
              onChange={onChangeStatus}
            />

            <div className="info"></div>
          </div>
          <div className="map-div">
            <LoadScript googleMapsApiKey={googleMapsApiKey}>
              <GoogleMap
                mapContainerStyle={mapStyles}
                onLoad={onLoadMap}
                center={center}
              >
                {filterMarkerList.length <= 0 && selectedDate === null
                  ? markerList.map(({ id, position, icon }) => {
                      return (
                        <Marker
                          key={id}
                          id={id}
                          position={position}
                          icon={icon}
                          onClick={() => onMarkerSelect({ id })}
                        >
                          {selectedMarker === id && (
                            <InfoWindow
                              position={position}
                              onCloseClick={() => setSelectedMarker(null)}
                            >
                              <div>
                                <table></table>
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      );
                    })
                  : filterMarkerList.map(({ id, position, icon }) => {
                      return (
                        <Marker
                          key={id}
                          id={id}
                          position={position}
                          icon={icon}
                          onClick={() => onMarkerSelect({ id, position })}
                        >
                          {selectedMarker === id && (
                            <InfoWindow
                              position={position}
                              onCloseClick={() => setSelectedMarker(null)}
                              key={id}
                            >
                              <div>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>Fecha de entrega:</td>
                                      <td>
                                        {delivery
                                          .filter((item) => item.id === id)
                                          .map((item) => item.deliveryDate)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Estatus</td>
                                      <td>
                                        {delivery
                                          .filter((item) => item.id === id)
                                          .map((item) => item.deliveryStatus)
                                          .map((item) => {
                                            return deliveryStatuses
                                              .filter(
                                                (status) => status.code === item
                                              )
                                              .map((status) => status.name);
                                          })}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Cliente</td>
                                      <td>
                                        {delivery
                                          .filter((item) => item.id === id)
                                          .map((item) => item.orderId)
                                          .map((item) => {
                                            return orders
                                              .filter(
                                                (order) => order.id === item
                                              )
                                              .map(
                                                (order) => order.customer.name
                                              );
                                          })}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Dirección</td>
                                      <td>
                                        {delivery
                                          .filter((item) => item.id === id)
                                          .map((item) => item.address)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Repartidor a cargo:</td>
                                      <td>
                                        {delivery
                                          .filter((item) => item.id === id)
                                          .map((item) => item.carrierId)
                                          .map((item) => {
                                            return carriers
                                              .filter(
                                                (carrier) => carrier.id === item
                                              )
                                              .map((carrier) => carrier.name);
                                          })}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <button
                                          onClick={editCarrier}
                                          className="btn btn-primary"
                                        >
                                          Editar
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </InfoWindow>
                          )}
                        </Marker>
                      );
                    })}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      </div>
    </Admin>
  );
}

export default MapRoutes;
