import DeliveryEdit from "../../../modals/admin/deliveryedit/DeliveryEdit";
import OrderStatus from "../../../modals/admin/orderstatus/OrderStatus";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Order from "../../../modals/admin/order/Order";
import { useState, useEffect, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { printPDF } from "../../../services/pdf";
import firebase from "firebase/compat/app";
import * as FileSaver from "file-saver";
import "firebase/compat/firestore";
import XLSX from "sheetjs-style";
import Swal from "sweetalert2";
import Admin from "../Admin";
import Moment from "moment";
import "./Orders.scss";

function Orders() {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showModalDelivery, setShowModalDelivery] = useState(false);
  const [itemsDelivery, setItemsDelivery] = useState({});
  const [configDelivery, setConfigDelivery] = useState([]);
  const [deliveryInfo, setDeliveryInfo] = useState([]);
  const account = JSON.parse(localStorage.getItem("account"));
  const companyId = account.access.at(0);
  const [data, setData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [item, setItem] = useState({});
  const [config, setConfig] = useState([]);

  useEffect(() => {
    (async () => {
      const config = await firebase
        .firestore()
        .collection("config")
        .doc("orderStatuses")
        .get();
      if (config.exists) {
        const data = config.data();
        if (data && data.list) {
          setConfig(data.list);
        } else {
          console.log(
            "La propiedad 'list' no está definida en los datos devueltos por la consulta a Firebase"
          );
        }
      } else {
        console.log(
          "No se encontró ningún documento con el ID 'orderStatuses' en la colección 'config'"
        );
      }

      const delivery = firebase
        .firestore()
        .collection("companies")
        .doc(companyId)
        .collection("delivery");

      delivery.onSnapshot((snap) => {
        let docs = [];
        if (!snap.empty) {
          docs = snap.docs.map((item) => {
            const dataMap = {
              id: item.id,
              ...item.data(),
            };
            return dataMap;
          });
        }
        setDeliveryInfo(docs);
      });

      const configDelivery = await firebase
        .firestore()
        .collection("config")
        .doc("deliveryStatuses")
        .get();
      if (configDelivery.exists) {
        const data = configDelivery.data();
        if (data && data.list) {
          setConfigDelivery(data.list);
        } else {
          console.log(
            "La propiedad 'list' no está definida en los datos devueltos por la consulta a Firebase"
          );
        }
      } else {
        console.log(
          "No se encontró ningún documento con el ID 'deliveryStatuses' en la colección 'config'"
        );
      }

      let collection = firebase
        .firestore()
        .collection("companies")
        .doc(companyId)
        .collection("orders");
      collection = collection.orderBy("createdAt", "desc");
      collection.onSnapshot((snap) => {
        let docs = [];
        const exportInfo = [];
        if (!snap.empty) {
          docs = snap.docs.map((item) => {
            const status = config
              .data()
              .list.find((x) => x.code === item.data().status);
            const dataMap = {
              subtotal: item.data().subtotal,
              iva: item.data().iva,
              total: item.data().total,
              id: item.id,
              status: item.data().status,
              statusName: status && status.name,
              date: item.data().createdAt
                ? Moment(item.data().createdAt.toDate()).format(
                    "DD/MM/YYYY"
                  )
                : null,
              correlativeNumber: item.data().correlativeNumber,
              items: item.data().items,
              customer: item.data().customer,
            };
            if (item.data().items && item.data().items.length > 0) {
              item.data().items.forEach((product) => {
                exportInfo.push({
                  ...dataMap,
                  producto: product.name,
                  cantidad: product.qty,
                  precio_unitario: product.price,
                  sku: product.sku,
                  unidad: product.unit,
                  descripcion: product.description,
                  categoria: product.category,
                  marca: product.brand,
                });
              });
            }
            if (item.data().customer) {
              exportInfo.push({
                ...dataMap,
                nombre: item.data().customer.name,
                id: item.data().customer.id,
                email: item.data().customer.email,
                telefono: item.data().customer.phone,
                direccion: item.data().customer.address,
              });
            }
            return dataMap;
          });
        }
        setExportData(exportInfo);
        setData(docs);
      });
    })();
  }, []);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }).format(number);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleNewModal = () => {
    setShowNewModal(!showNewModal);
  };

  const toggleModalDelivery = () => {
    setShowModalDelivery(!showModalDelivery);
  };

  const edit = (item) => {
    setItem(item);
    toggleModal();
  };

  const exportToXLS = () => {
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/vnd.ms-excel" });
    FileSaver.saveAs(blob, "orders.xlsx");
  };

  // const download = (data) => {
  //   console.log("download", data);
  //   printPDF(data);
  // };

  const deliveryApointment = (data) => {
    if (data.status === 500) {
      Swal.fire({
        title: "Error",
        text: "Esta orden ya esta finalizada",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
      });
    }else if (deliveryInfo.filter((x) => x.orderId === data.id).length > 0) {
      Swal.fire({
        title: "Atención",
        text: "Ya existe una orden de despacho para esta orden,   puede editarla en la sección de entregas",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ir a la sección de entregas",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/admin/delivery`);
        }
      });
    } else {
      setItemsDelivery({
        orderId: data.id,
        orderNumber: data.correlativeNumber,
        address: data.customer.address,
        customerData: {
          name: data.customer.name,
          phone: data.customer.phone,
        },
        position: {
          lat: data.customer.lat,
          lng: data.customer.lng,
        },
      });
      toggleModalDelivery();
    }
  };

  return (
    <Admin showLoading={showLoading}>
      <OrderStatus
        setShow={showModal}
        toggleModal={toggleModal}
        showLoading={setShowLoading}
        values={item}
        setValues={setItem}
        status={config}
      />
      <DeliveryEdit
        setShow={showModalDelivery}
        toggleModal={toggleModalDelivery}
        showLoading={setShowLoading}
        values={itemsDelivery}
        status={configDelivery}
      />
      <h1 className="hidden sm:block text-3xl mt-5 text-center sm:bg-blue-500 sm:w-fit sm:pl-10 sm:-ml-10 sm:pr-5 sm:rounded-full sm:py-2 font-bold sm:text-white dark:text-gray-100">
        Órdenes
      </h1>
      <Order setNewShow={showNewModal} toggleNewModal={toggleNewModal} />
      <div className="sm:flex sm:aling-center sm:py-5 items-center">
        <form className="sm:mb-0 sm:mt-0 mt-5 mb-5">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative sm:w-96">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Buscar orden"
              required
            />
            <button
              type="submit"
              className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Buscar
            </button>
          </div>
        </form>
        <div className="flex w-full justify-end items-center px-1 mb-5 sm:mb-0">
          <button
            onClick={exportToXLS}
            className="flex items-center focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 mr-2"
          >
            <span className="hidden sm:block">Descargar</span>
            <i className="fas fa-file sm:ml-2"></i>
          </button>
          <button
            onClick={toggleNewModal}
            className="flex items-center focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            <span className="hidden sm:block">Agregar</span>
            <i className="fas fa-plus sm:ml-2"></i>
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto overflow-y-auto sm:max-h-[30em]">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                N°Orden
              </th>
              <th scope="col" className="px-6 py-3">
                Cliente
              </th>
              <th scope="col" className="px-6 py-3">
                Fecha
              </th>
              <th scope="col" className="px-6 py-3">
                Total
              </th>
              <th scope="col" className="px-6 py-3">
                Estado
              </th>
              <th scope="col" className="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                key={item.id}
              >
                <th
                  scope="row"
                  className="px-6 text-left py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  N°{item.correlativeNumber}
                </th>
                <td className="px-6 py-4">{item.customer.name}</td>
                <td className="px-6 py-4">{item.date}</td>
                <td className="px-6 py-4">{formatNumber(item.total)}</td>
                <td className="px-6 py-4">{item.statusName}</td>
                <td className="px-6 py-4 cursor-pointer">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex justify-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm">
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            <div
                              className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                              onClick={() =>
                                navigate(`/admin/orderdetail/${item.id}`)
                              }
                            >
                              Ver Detalle
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div
                              className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                              onClick={() => edit(item)}
                            >
                              Editar estado
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div
                              className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                              onClick={() => deliveryApointment(item)}
                            >
                              Agendar entrega
                            </div>
                          </Menu.Item>
                          {/* 
                          //AQUI VA EL DESCARGAR PDF PERO NO ESTA IMPLEMENTADO
                          <Menu.Item>
                            <div
                              className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                              onClick={() => edit(item)}
                            >
                              Editar estado
                            </div>
                          </Menu.Item> */}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/**    <div className="admin__content__header">
        <div className="admin__content__header__title">Órdenes</div>
        <div className="admin__content__header__actions">
          <button className="btn btn-primary" onClick={toggleNewModal}>
            Crear Orden
          </button>
          <button className="btn btn-primary" onClick={exportToXLS}>
            <i className="fa-solid fa-file-excel"></i> Exportar
          </button>
        </div>
        <Order setNewShow={showNewModal} toggleNewModal={toggleNewModal} />
      </div>
      {loaded && (
        <div className="admin__content__body">
          <OrderStatus
            setShow={showModal}
            toggleModal={toggleModal}
            showLoading={setShowLoading}
            values={item}
            setValues={setItem}
            status={config}
          />
          <DeliveryEdit
            setShow={showModalDelivery}
            toggleModal={toggleModalDelivery}
            showLoading={setShowLoading}
            values={itemsDelivery}
            status={configDelivery}
          />

          {data.length ? (
            <>
              <Table
                {...tableProps}
                childComponents={{
                  cellText: {
                    content: (props) => {
                      if (props.column.key === "actions") {
                        return (
                          <>
                            <Dropdown className="actions-dropdown">
                              <Dropdown.Toggle id="dropdown-basic">
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    navigate(
                                      `/admin/orderdetail/${props.rowData.id}`
                                    )
                                  }
                                >
                                  Ver detalle
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => edit(props.rowData)}
                                >
                                  Cambiar estado
                                </Dropdown.Item>
                                {props.rowData.status === 300 && (
                                  <Dropdown.Item
                                    onClick={() => {
                                      if (
                                        deliveryInfo.filter(
                                          (x) => x.orderId === props.rowData.id
                                        ).length > 0
                                      ) {
                                        Swal.fire({
                                          title: "Atención",
                                          text: "Ya existe una orden de despacho para esta orden,   puede editarla en la sección de entregas",
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          confirmButtonText: "ir a la sección de entregas",
                                          cancelButtonText: "Cancelar",
                                        }).then((result) => {
                                          if (result.isConfirmed) {
                                            navigate(`/admin/delivery`);
                                          }
                                        });
                                      } else {
                                        deliveryApointment(props.rowData);
                                      }
                                    }}
                                  >
                                    Agendar entrega
                                  </Dropdown.Item>
                                )}

                                 <Dropdown.Item
                                  onClick={() => download(props.rowData)}
                                >
                                  Descargar OC
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                        );
                      }
                    },
                  },
                }}
                data={data}
              />
            </>
          ) : (
            <div className="empty-content">
              <i className="fa-regular fa-folder-open"></i>
              <div className="empty-content__body">
                Aún no hay órdenes para mostrar
              </div>
            </div>
          )}
        </div>
      )}*/}
    </Admin>
  );
}

export default Orders;
