import "./Products.scss";
import Admin from "../Admin";
import { useState, useEffect, Fragment } from "react";
import Product from "../../../modals/admin/product/Product";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Swal from "sweetalert2";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function Products() {
  const [loaded, setLoaded] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [item, setItem] = useState({});

  const account = JSON.parse(localStorage.getItem("account"));
  const companyId = account.access.at(0);

  const userId = firebase.auth().currentUser.uid;

  useEffect(() => {
    firebase
      .firestore()
      .collection("companies")
      .doc(companyId)
      .collection("products")
      .where("deletedAt", "==", null)
      .onSnapshot((snap) => {
        if (!snap.empty) {
          const data = snap.docs.map((item, index) => ({
            name: item.data().name,
            description: item.data().description,
            stock: item.data().stock,
            id: item.id,
            price: item.data().price ? item.data().price : 0,
            brand: item.data().brand,
            sku: item.data().sku,
            images: item.data().images,
          }));
          setData(data);
        }
        setLoaded(true);
      });
  }, []);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }).format(number);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const edit = (item) => {
    console.log("entra al edit", item);
    setItem(item);
    toggleModal();
  };

  const add = () => {
    setItem({});
    toggleModal();
  };

  const deleted = async (item) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar " + item.name + "?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const doc = firebase
          .firestore()
          .collection("companies")
          .doc(companyId)
          .collection("products")
          .doc(item.id);
        await doc.update({
          deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
          deletedBy: userId,
        });
        Swal.fire({
          icon: "success",
          title: "Eliminado",
          text: "Producto eliminado con exito",
        });
      }
    });
  };

  return (
    <Admin showLoading={showLoading}>
      <Product
        setShow={showModal}
        toggleModal={toggleModal}
        showLoading={setShowLoading}
        values={item}
        setValues={setItem}
      />
      <h1 className="text-3xl mt-5 text-center sm:bg-blue-500 sm:w-fit sm:pl-10 sm:-ml-10 sm:pr-5 sm:rounded-full sm:py-2 font-bold sm:text-white dark:text-gray-100">
        Productos
      </h1>
      <div className="botones flex w-full sm:justify-end items-center py-3 px-1">
        <button
          onClick={add}
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          Agregar
          <i className="fas fa-plus ml-2"></i>
        </button>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Producto
              </th>
              <th scope="col" className="px-6 py-3">
                precio
              </th>
              <th scope="col" className="px-6 py-3">
                Stock
              </th>
              <th scope="col" className="px-1 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                key={item.id}
              >
                <th
                  scope="row"
                  className="px-6 text-left py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {item.name}
                </th>
                <td className="px-6 py-4">
                  {new Intl.NumberFormat("es-CL", {
                    style: "currency",
                    currency: "CLP",
                  }).format(item.price)}
                </td>
                <td className="px-6 py-4">{item.stock}</td>
                <td className="px-6 py-4 cursor-pointer">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            <a
                              href="#"
                              className="text-gray-700 block px-4 py-2 text-sm"
                              onClick={() => edit(item)}
                            >
                              Editar
                            </a>
                          </Menu.Item>
                          <Menu.Item>
                            <a
                              href="#"
                              className="text-gray-700 block px-4 py-2 text-sm"
                              onClick={() => deleted(item)}
                            >
                              Eliminar
                            </a>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Admin>
  );
}

export default Products;
