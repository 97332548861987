import { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "dayjs/locale/es";
import locale from "antd/locale/es_ES";
import { ConfigProvider } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import "./DeliveryEdit.scss";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import Swal from "sweetalert2";

function DeliveryEdit({
  setShow,
  toggleModal,
  showLoading,
  values,
  setValues,
  status,
}) {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const fields = ["formatted_address", "name", "geometry.location"];
  const restrictions = {
    country: "CL",
  };
  const [carriers, setCarriers] = useState([]);
  const account = JSON.parse(localStorage.getItem("account"));
  const companyId = account.access.at(0);
  const [selectedCarrierId, setSelectedCarrierId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDirection, setSelectedDirection] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const libraries = useMemo(() => ["places"], []);

  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  const handlePlaceChanged = () => {
    if (searchResult) {
      const location = {
        lat: searchResult.getPlace().geometry.location.lat(),
        lng: searchResult.getPlace().geometry.location.lng(),
      };
      setSelectedDirection(location);
    }
  };

  useEffect(() => {
    const getCarriers = async () => {
      const carriers = await firebase
        .firestore()
        .collection("carriers")
        .where("deletedAt", "==", null)
        .get();
      const docs = [];
      carriers.forEach((doc) => {
        docs.push({
          id: doc.id,
          name: doc.data().name,
        });
      });
      setCarriers(docs);
    };
    getCarriers();
  }, []);

  const handleOnCancel = () => {
    setSelectedCarrierId(null);
    setSelectedDate(null);
    setSelectedStatus(null);
    setSelectedDirection(null);
    setSearchResult(null);
    reset(values);
    toggleModal();
  };

  const onSubmit = async (data) => {
    showLoading(true);
    try {
      if (!data.id) {
        data.carrierId = selectedCarrierId;
        data.orderId = values.orderId;
        data.address = values.address;
        data.customerData = values.customerData;
        data.orderNumber = values.orderNumber;
        data.deliveryStatus = parseInt(selectedStatus);
        data.deliveryDate = selectedDate ? selectedDate : data.deliveryDate;
        data.position = selectedDirection ? selectedDirection : values.position;
        data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
        data.createdBy = firebase.auth().currentUser.uid;
        data.deletedBy = null;
        data.deletedAt = null;
      }
      if (data.id) {
        if (selectedStatus) {
          data.deliveryStatus = selectedStatus
            ? parseInt(selectedStatus)
            : data.deliveryStatus;
        }
        if (searchResult.getPlace().name) {
          data.address = searchResult.getPlace().name
            ? searchResult.getPlace().name
            : data.address;
        }
        if (selectedCarrierId) {
          data.carrierId = selectedCarrierId
            ? selectedCarrierId
            : data.carrierId;
        }
        if (selectedDirection) {
          data.position = selectedDirection ? selectedDirection : data.position;
        }
        if (selectedDate) {
          data.deliveryDate = selectedDate ? selectedDate : data.deliveryDate;
        }
        data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
        data.updatedBy = firebase.auth().currentUser.uid;
      }

      const deliveryRef = firebase
        .firestore()
        .collection("companies")
        .doc(companyId)
        .collection("delivery")
        .doc(data.id);
      data.id = deliveryRef.id;
      await deliveryRef.set(data, { merge: true });
      Swal.fire({
        title: "¡Éxito!",
        text: "La información se ha guardado correctamente.",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
      toggleModal();
      showLoading(false);
    } catch (error) {
      console.log(error);
      showLoading(false);
    }
  };

  return (
    <div>
      {/* <!-- Main modal --> */}
      <div
        id="defaultModal"
        tabIndex="-1"
        aria-hidden="true"
        className={`fixed top-0 left-0 right-0 z-50 bg-gray-500 bg-opacity-50 ${
          !setShow ? "hidden" : "block"
        } flex justify-center items-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen`}
      >
        <div className="relative w-full max-w-2xl max-h-full">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* <!-- Modal header --> */}
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {values.id ? "Editar" : "Agendar"} entrega
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="defaultModal"
                onClick={handleOnCancel}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-6 space-y-6">
              <form className="space-y-4">
                <div className="grid grid-cols-1 gap-6">
                  <label
                    htmlFor="carrier"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Repartidor
                  </label>
                  <select
                    name="carrierId"
                    className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light ${
                      errors.carrier && "border-red-700"
                    }`}
                    id="carrier"
                    onChange={(e) => setSelectedCarrierId(e.target.value)}
                    defaultValue={
                      values.carrierId && values.carrierId !== null
                        ? values.carrierId
                        : "default"
                    }
                  >
                    <option value="default" disabled hidden>
                      Seleccionar repartidor
                    </option>
                    {carriers.map((carrier) => (
                      <option key={carrier.id} value={carrier.id}>
                        {`${carrier.name}`}
                      </option>
                    ))}
                  </select>
                  {errors.carrier && errors.carrier.type === "required" && (
                    <span className="text-red-500">
                      Este campo es requerido
                    </span>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="date"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Fecha de entrega
                  </label>
                  <ConfigProvider locale={locale}>
                    <DatePicker
                      locale={locale}
                      onChange={(date, dateString) => {
                        setSelectedDate(dateString);
                      }}
                      format="DD/MM/YYYY"
                      defaultValue={
                        values.deliveryDate
                          ? dayjs(values.deliveryDate, "DD/MM/YYYY")
                          : null
                      }
                      style={{ width: "100%" }}
                    />
                  </ConfigProvider>
                  {errors.date && errors.date.type === "required" && (
                    <span className="text-danger">Este campo es requerido</span>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="status"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Estado
                  </label>
                  <select
                    className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light ${
                      errors.status && "border-red-700"
                    }`}
                    id="status"
                    name="status"
                    onChange={(e) => {
                      setSelectedStatus(e.target.value);
                    }}
                    defaultValue={
                      values.deliveryStatus && values.deliveryStatus !== null
                        ? values.deliveryStatus
                        : "default"
                    }
                  >
                    <option value="default" disabled hidden>
                      Seleccionar estado
                    </option>

                    {status.length &&
                      status.map((item) => (
                        <option key={item.code} value={item.code}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                  {errors.status && errors.status.type === "required" && (
                    <span className="text-danger">Este campo es requerido</span>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="direction"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Dirección
                  </label>
                  <LoadScript
                    googleMapsApiKey="AIzaSyD0IQUwQacrfeHznzc2gfaeb12qw6U3jzg"
                    libraries={libraries}
                  >
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={handlePlaceChanged}
                      fields={fields}
                      restrictions={restrictions}
                    >
                      <input
                        type="text"
                        className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light ${
                          errors.direction && "border-red-700"
                        }`}
                        id="direction"
                        name="direction"
                        defaultValue={values.address}
                        placeholder="Dirección"
                      />
                    </Autocomplete>
                  </LoadScript>
                </div>
              </form>
            </div>
            {/* <!-- Modal footer --> */}
            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                onClick={handleSubmit(onSubmit)}
                data-modal-hide="defaultModal"
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Aceptar
              </button>
              <button
                onClick={handleOnCancel}
                data-modal-hide="defaultModal"
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal show={setShow} onHide={handleOnCancel} onEnter={onEnter} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Entrega</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="login__content__form__body">
            <div className="row">
              <div className="col">
                <label htmlFor="carrier">Repartidor</label>
                <select
                  name="carrierId"
                  className={`form-select ${errors.carrier && "is-invalid"}`}
                  id="carrier"
                  onChange={(e) => setSelectedCarrierId(e.target.value)}
                  defaultValue={
                    values.carrierId && values.carrierId !== null
                      ? values.carrierId
                      : "default"
                  }
                >
                  <option value="default" disabled hidden>
                    Seleccionar repartidor
                  </option>
                  {carriers.map((carrier) => (
                    <option key={carrier.id} value={carrier.id}>
                      {`${carrier.name}`}
                    </option>
                  ))}
                </select>
                {errors.carrier && errors.carrier.type === "required" && (
                  <span className="text-danger">Este campo es requerido</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="date">Fecha de entrega</label>
                <ConfigProvider locale={locale}>
                  <DatePicker
                    locale={locale}
                    onChange={(date, dateString) => {
                      setSelectedDate(dateString);
                    }}
                    format="DD/MM/YYYY"
                    defaultValue={
                      values.deliveryDate
                        ? dayjs(values.deliveryDate, "DD/MM/YYYY")
                        : null
                    }
                    style={{ width: "100%" }}
                  />
                </ConfigProvider>
                {errors.date && errors.date.type === "required" && (
                  <span className="text-danger">Este campo es requerido</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="status">Estado</label>
                <select
                  className={`form-select ${errors.status && "is-invalid"}`}
                  id="status"
                  name="status"
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                  }}
                  defaultValue={
                    values.deliveryStatus && values.deliveryStatus !== null
                      ? values.deliveryStatus
                      : "default"
                  }
                >
                  <option value="default" disabled hidden>
                    Seleccionar estado
                  </option>

                  {status.length &&
                    status.map((item) => (
                      <option key={item.code} value={item.code}>
                        {item.name}
                      </option>
                    ))}
                </select>
                {errors.status && errors.status.type === "required" && (
                  <span className="text-danger">Este campo es requerido</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="direction">Dirección</label>
                <LoadScript
                  googleMapsApiKey="AIzaSyD0IQUwQacrfeHznzc2gfaeb12qw6U3jzg"
                  libraries={libraries}
                >
                  <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={handlePlaceChanged}
                    fields={fields}
                    restrictions={restrictions}
                  >
                    <input
                      type="text"
                      className={`form-control ${
                        errors.direction && "is-invalid"
                      }`}
                      id="direction"
                      name="direction"
                      defaultValue={values.address}
                      placeholder="Dirección"
                    />
                  </Autocomplete>
                </LoadScript>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleOnCancel}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
          >
            Guardar
          </button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}

export default DeliveryEdit;
