import firebase from "firebase/compat/app";
let config = {
  apiKey: "AIzaSyAY3DvhYdbLrmR4532F3U1kAav6dxsvUMA",
  authDomain: "h2osys-dev.firebaseapp.com",
  projectId: "h2osys-dev",
  storageBucket: "h2osys-dev.appspot.com",
  messagingSenderId: "778423666931",
  appId: "1:778423666931:web:fcfc04e49121d93fca4f86",
};
if (process.env.NODE_ENV === "production") {
  config = {
    apiKey: "AIzaSyAQhQ0aqtNkL1UA4O1N7veDFD28DFN6UYU",
    authDomain: "h2osys-prod.firebaseapp.com",
    projectId: "h2osys-prod",
    storageBucket: "h2osys-prod.appspot.com",
    messagingSenderId: "390314391116",
    appId: "1:390314391116:web:c1fbdbad1aae1178fce6e9",
    measurementId: "G-ZV4LB079KS",
  };
}

export default firebase.initializeApp(config);
