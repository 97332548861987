import "./OrderStatus.scss";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Swal from "sweetalert2";

function OrderStatus({
  setShow,
  toggleModal,
  showLoading,
  values,
  setValues,
  status,
}) {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const account = JSON.parse(localStorage.getItem("account"));
  const companyId = account.access.at(0);

  useEffect(() => {
    if (values) {
      reset(values);
      setValues(values);
    }
  }, [values]);

  const onSubmit = async (data) => {
    showLoading(true);
    try {
      const doc = firebase
        .firestore()
        .collection("companies")
        .doc(companyId)
        .collection("orders")
        .doc(data.id);
      await doc.update({ status: parseInt(data.status) });
      Swal.fire({
        title: "Estado asignado exitosamente",
        text: "el estado de la orden ha sido cambiado exitosamente",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
      toggleModal();
    } catch (e) {
      alert(e.message);
    }
    showLoading(false);
  };

  return (
    <div>
      {/* <!-- Main modal --> */}
      <div
        id="defaultModal"
        aria-hidden="true"
        className={`fixed top-0 left-0 right-0 z-50 w-full h-screen flex justify-center items-center bg-gray-500 bg-opacity-50 p-4 overflow-x-hidden ${
          !setShow ? "hidden" : "block"
        }  md:inset-0  max-h-screen`}
      >
        <div className="relative w-full max-w-2xl max-h-full">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* <!-- Modal header --> */}
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Cambiar estado de orden
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={toggleModal}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-6 space-y-6">
              <form>
                <div className="flex flex-col">
                  <label
                    htmlFor="status"
                    className="leading-loose text-gray-600 dark:text-gray-200"
                  >
                    Por favor asigne un estado a la orden:
                  </label>
                  <select
                    id="status"
                    name="status"
                    {...register("status", { required: true })}
                    className={`form-select w-full text-gray-800 px-2 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-blue-500 focus:bg-gray-50 dark:focus:bg-gray-800 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300`}
                  >
                    {status.map((item) => (
                      <option value={item.code} key={item.code}>{item.name}</option>
                    ))}
                  </select>
                  {errors.status && (
                    <span className="text-red-500">Este campo es requerido</span>
                  )}
                </div>
              </form>
            </div>
            {/* <!-- Modal footer --> */}
            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                data-modal-hide="defaultModal"
                type="button"
                onClick={handleSubmit(onSubmit)}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Aceptar
              </button>
              <button
                data-modal-hide="defaultModal"
                onClick={toggleModal}
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal show={setShow} onHide={toggleModal} onEnter={onEnter} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Orden</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="login__content__form__body">
            <div className="row">
              <div className="col">
                <label htmlFor="status">Estado</label>
                <select
                  className={`form-select ${errors.status && "is-invalid"}`}
                  id="status"
                  {...register("status", { required: true })}
                >
                  {status.length &&
                    status.map((item) => (
                      <option key={item.code} value={item.code}>
                        {item.name}
                      </option>
                    ))}
                </select>
                {errors.status && errors.status.type === "required" && (
                  <span className="text-danger">Este campo es requerido</span>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleModal}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
          >
            Guardar
          </button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}

export default OrderStatus;
