import { useState } from "react";
import Loading from "../../components/loading/Loading";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

function Admin({ children, showLoading}) {
  const navigate = useNavigate();
  const [showConfig, setShowConfig] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const items = [
    {
      path: "/admin/dashboard",
      icon: "fa-home",
      name: "Dashboard",
      roles: ["admin", "company"],
    },
    {
      path: "/admin/accounts",
      icon: "fa-users",
      name: "Cuentas",
      roles: ["admin"],
    },
    {
      path: "/admin/customers",
      icon: "fa-people-group",
      name: "Clientes",
      roles: ["admin", "company"],
    },
    {
      path: "/admin/carriers",
      icon: "fa-people-carry-box",
      name: "Repartidores",
      roles: ["admin"],
    },
    {
      path: "/admin/products",
      icon: "fa-bowl-food",
      name: "Productos",
      roles: ["company"],
    },
    {
      path: "/admin/orders",
      icon: "fa-list",
      name: "Órdenes",
      roles: ["admin", "company", "delivery"],
    },
    {
      path: "/admin/delivery",
      icon: "fa-truck",
      name: "Entregas",
      roles: ["company", "admin", "delivery"],
    },
    {
      path: "/admin/routes",
      icon: "fa-route",
      name: "Rutas",
      roles: ["admin", "company", "delivery"],
    },
  ];
  const account = JSON.parse(localStorage.getItem("account"));

  const logout = async () => {
    await firebase.auth().signOut();
    navigate("/login");
  };

  const goToPath = (path) => {
    navigate(path);
    setShowSidebar(false);
  };

  const deactivate = () => {
    setShowConfig(false);
    setShowSidebar(false);
  };

  return (
    <>
      <Loading setShow={showLoading} />
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                onClick={() => setShowSidebar(!showSidebar)}
                className={`inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600`}
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <div onClick={()=>navigate("/admin/dashboard")} className="cursor-pointer flex ml-2 md:mr-24">
                  <img
                    src="/assets/img/favicon.png"
                    className="h-8 mr-3"
                    alt="Logo"
                  />
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                  {account?.companyName}
                </span>
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex items-center ml-3">
                <div>
                  <button
                    type="button"
                    className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                    aria-expanded="false"
                    data-dropdown-toggle="dropdown-user"
                    onClick={() => setShowConfig(!showConfig)}
                  >
                    <span className="sr-only">Open user menu</span>
                    <div
                      className="w-8 h-8 rounded-full bg-gray-500 text-white flex items-center justify-center"
                    >{account.name && account.name.charAt()}</div>
                  </button>
                </div>
                <div
                  className={`z-50 ${!showConfig && "hidden"} absolute top-10 right-5 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600`}
                  id="dropdown-user"
                >
                  <div className="px-4 py-3" role="none">
                    <p
                      className="text-sm text-gray-900 dark:text-white"
                      role="none"
                    >
                      {account?.name}
                    </p>
                    <p
                      className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                      role="none"
                    >
                      {account?.email}
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                        role="menuitem"
                      >
                        Ajustes
                      </a>
                    </li>
                    <li>
                      <div
                        onClick={logout}
                        className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                        role="menuitem"
                      >
                        Cerrar sesión
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform ${!showSidebar && "-translate-x-full"} bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <div className="space-y-2 font-medium">
            {items
              .filter((item) => item.roles.indexOf(account.role) !== -1)
              .map((item) => (
                <div key={item.path}>
                  <div
                    onClick={() => goToPath(item.path)}
                    className="cursor-pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-blue-200 dark:hover:bg-blue-700"
                  >
                    <i className={`fa-solid ${item.icon}`}></i>
                    <span className="ml-3">{item.name}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </aside>

      <div className="p-4 mt-12 flex flex-col sm:block sm:ml-64" onClick={deactivate}>{children}</div>
    </>
  );
}
/*
<div className="admin">
        <div className="admin__navbar">
          <div className="admin__navbar__box__image">
              <img src="/assets/img/favicon.png" alt="logo" onClick={()=> navigate(
                "/admin/dashboard")}/>
          </div>
          <div className="admin__navbar__box">
            <Dropdown className="user-dropdown">
              <Dropdown.Toggle id="dropdown-basic">
                <div className="user-dropdown__avatar">
                  {account.name && account.name.charAt()}
                </div>
                <div className="user-dropdown__content">
                  {account.name}
                  <br />
                  <small>{account.email}</small>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={logout}>Cerrar sesión</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div
          className="admin__sidebar"
        >
          {items
            .filter((item) => item.roles.indexOf(account.role) !== -1)
            .map((item) => (
              <OverlayTrigger
                key={item.path}
                placement="right"
                overlay={
                    <Tooltip id={`tooltip-${item.path}`}>{item.name}</Tooltip>
                }
              >
                <div
                  className={`admin__sidebar__item ${
                    window.location.href.indexOf(item.path) !== -1 &&
                    "admin__sidebar__item-active"
                  }`}
                  key={item.path}
                  onClick={() => goToPath(item.path)}
                >
                  <i className={`fa-solid ${item.icon}`}></i>
                  <div className="admin__sidebar__item__content">
                    {item.name}
                  </div>
                </div>
              </OverlayTrigger>
            ))}
        </div>
        <div
          className="admin__content"
        >
          {children}
        </div>
      </div> 
*/
export default Admin;
