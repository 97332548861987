import React, { useState, useEffect } from "react";
import "./Order.scss";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Swal from "sweetalert2";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

function Order({ setNewShow, toggleNewModal }) {
  const { handleSubmit } = useForm();

  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [ correlativeOrderNumber, setCorrelativeOrderNumber] = useState(0);
  const account = JSON.parse(localStorage.getItem("account"));
  const companyId = account.access.at(0);

  const onSubmit = async (data) => {
    if (Object.entries(selectedCustomer).length !== 0) { 
    try {
      const correlative = correlativeOrderNumber;
      if (!data.id) {
        data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
        data.createdBy = account.id;
        data.deletedAt = null;
        data.deletedBy = null;
        data.status = 100;
        data.correlativeNumber = correlative;
        data.customer = {
          name: selectedCustomer.name,
          id: selectedCustomer.id,
          address: selectedCustomer.address,
          rut: selectedCustomer.rut,
          phone: selectedCustomer.phone,
          email: selectedCustomer.email,
          lat: selectedCustomer.lat,
          lng: selectedCustomer.lng,
        };
        data.items = [];
        data.total = 0;
        data.subtotal = 0;
        data.iva = 0;
      }
      const doc2 = firebase.firestore().collection("companies").doc(companyId);
      await doc2.set({ correlativeOrder: correlative + 1 }, { merge: true });
      const doc = firebase
        .firestore()
        .collection("companies")
        .doc(companyId)
        .collection("orders")
        .doc(data.id);
      data.id = doc.id;
      await doc.set(data, { merge: true });

      Swal.fire({
        title: "Registro creado exitosamente",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
      toggleNewModal();
      if (data.id) {
        navigate(`/admin/orderdetail/${data.id}`);
      }
    } catch (e) {
      alert(e.message);
    }
  }else{
    Swal.fire({
      title: "Debe seleccionar un cliente",
      icon: "error",
      confirmButtonText: "Aceptar",
    });
  }
  };

  useEffect(() => {
    const ubication = firebase
      .firestore()
      .collection("companies")
      .doc(companyId)
      .collection("customers")
      .where("deletedAt", "==", null);
    if (ubication != null) {
      ubication.onSnapshot((snap) => {
        if (!snap.empty) {
          const data = snap.docs.map((item) => ({
            name: item.data().name,
            id: item.id,
            address: item.data().address,
            phone: item.data().phone,
            email: item.data().email,
            lat: item.data().lat,
            lng: item.data().lng,
            rut: item.data().rut,
          }));
          setCustomerData(data);
        }
      });
    } else {
      console.log("no existe la coleccion");
    }
    const correlativeOrder = firebase
      .firestore()
      .collection("companies")
      .doc(companyId);

    correlativeOrder.onSnapshot((snap) => {
      if (!snap.empty) {
        const data = snap.data().correlativeOrder;
        setCorrelativeOrderNumber(data);
      }
    });
  }, []);

  const cancel = () => {
    toggleNewModal();
    setSelectedCustomer({});
    document.getElementById("customer").value = "Seleccione un cliente";
  };

  const onSelectCustomer = (e) => {
    console.log(e.target.value);
    setSelectedCustomer(
      customerData.find((item) => item.id === e.target.value)
    );
  };

  return (
    <div>
      <div
        aria-hidden="true"
        className={`fixed top-0 left-0 ${
          setNewShow ? "block" : "hidden"
        } right-0 z-50 w-full flex items-center justify-center bg-gray-400 bg-opacity-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen `}
      >
        <div className="relative w-full max-w-2xl max-h-full">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* <!-- Modal header --> */}
            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Nueva orden
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => toggleNewModal()}
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            {/* <!-- Modal body --> */}

            <div className="relative p-4 h-full">
            <label
              htmlFor="customer"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Para poder continuar, debes seleccionar un cliente:
            </label>
            <select
              id="customer"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => onSelectCustomer(e)} placeholder="Seleccione a un cliente"
            >
              <option value="Seleccione un cliente">Seleccione un cliente</option>
              {customerData.map((item) => (
                <option value={item.id} key={item.id}>{item.name}</option>
              ))}
            </select>
            </div>
            {/* <Select
                  id="customer"
                  options={customerData}
                  
                  placeholder={"Escoger cliente"}
                  onChange={(items) => setSelectedCustomer(items)}
                /> */}
            {/* <!-- Modal footer --> */}
            <div className="flex items-center justify-between p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                data-modal-hide="staticModal"
                type="button"
                onClick={() => cancel()}
                className="text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-lg text-sm font-medium px-5 py-2.5 hover:text-white focus:z-10"
              >
                Cancelar
              </button>
              <button
                data-modal-hide="staticModal"
                type="button"
                className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-100 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                onClick={handleSubmit(onSubmit)}
              >
                aceptar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal show={setNewShow} onHide={toggleNewModal} size="md">
        <Modal.Header>
          <div>Crear orden</div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">
              <label htmlFor="customer">
                Seleccione al cliente para empezar
              </label>
              <Select
                id="customer"
                options={customerData}
                placeholder={"Escoger cliente"}
                onChange={(items) => setSelectedCustomer(items)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
                      <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleNewModal}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit(onSubmit)}
          >
            Guardar
          </button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}

export default Order;
