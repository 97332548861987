import "./Customers.scss";
import Admin from "../Admin";
import { useState, useEffect, Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Customer from "../../../modals/admin/customer/Customer";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Swal from "sweetalert2";
import { Menu, Transition } from "@headlessui/react";

function Customers() {
  const [showLoading, setShowLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [item, setItem] = useState({});
  const account = JSON.parse(localStorage.getItem("account"));
  const companyId = account.access.at(0);
  const userId = firebase.auth().currentUser.uid;

  useEffect(() => {
    const ubication = firebase
      .firestore()
      .collection("companies")
      .doc(companyId)
      .collection("customers")
      .where("deletedAt", "==", null);
    if (ubication != null) {
      ubication.onSnapshot((snap) => {
        if (!snap.empty) {
          const data = snap.docs.map((item, index) => ({
            name: item.data().name,
            rut: item.data().rut,
            email: item.data().email,
            phone: item.data().phone,
            address: item.data().address,
            formatedAddress: item.data().formatedAddress,
            area: item.data().area,
            lat: item.data().lat,
            lng: item.data().lng,
            id: item.id,
          }));
          setData(data);
        }
      });
    } else {
      console.log("no existe la coleccion");
    }
  }, []);

  const newCustomer = () => {
  setItem({
      name: "",
      rut: "",
      email: "",
      phone: "",
      address: "",
      formatedAddress: "",
      area: "",
      lat: "",
      lng: "",
    });
    toggleModal();
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const edit = (item) => {
    setItem(item);
    toggleModal();
  };

  const deleted = async (item) => {
    Swal.fire({
      title: "¿Estás seguro de eliminar " + item.name + "?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const doc = firebase
          .firestore()
          .collection("companies")
          .doc(companyId)
          .collection("customers")
          .doc(item.id);
        await doc.update({
          deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
          deletedBy: userId,
        });
        Swal.fire({
          icon: "success",
          title: "Eliminado",
          text: "Cliente eliminado con exito",
        });
      }
    });
  };

  return (
    <Admin showLoading={showLoading}>
      <Customer
        setShow={showModal}
        toggleModal={toggleModal}
        showLoading={setShowLoading}
        values={item}
      />
      <h1 className="text-3xl mt-5 text-center sm:bg-blue-500 sm:w-fit sm:pl-10 sm:-ml-10 sm:pr-5 sm:rounded-full sm:py-2 font-bold sm:text-white dark:text-gray-100">
        Clientes
      </h1>
      <div className="botones flex w-full sm:justify-end items-center py-3 px-1">
        <button
          onClick={newCustomer}
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          Agregar
          <i className="fas fa-plus ml-2"></i>
        </button>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Nombre
              </th>
              <th scope="col" className="px-6 py-3">
                Rut
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Teléfono
              </th>
              <th scope="col" className="px-6 py-3">
                Dirección
              </th>
              <th scope="col" className="px-6 py-3">
                Comuna
              </th>
              <th scope="col" className="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                key={item.id}
              >
                <th
                  scope="row"
                  className="px-6 text-left py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {item.name}
                </th>
                <td className="px-6 py-4">{item.rut}</td>
                <td className="px-6 py-4">{item.email}</td>
                <td className="px-6 py-4">{item.phone}</td>
                <td className="px-6 py-4">{item.address}</td>
                <td className="px-6 py-4">{item.area}</td>
                <td className="px-6 py-4 cursor-pointer">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex justify-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm">
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            <div
                              className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                              onClick={() => edit(item)}
                            >
                              Editar
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div
                              className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                              onClick={() => deleted(item)}
                            >
                              Eliminar
                            </div>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* <div className="admin__content__header">
        <div className="admin__content__header__title">Clientes</div>
        <div className="admin__content__header__actions">
          <button
            type="button"
            className="btn btn-primary"
            onClick={newCustomer}
          >
            Agregar cliente
          </button>
        </div>
      </div>
      <div className="admin__content__body">
        <Customer
          setShow={showModal}
          toggleModal={toggleModal}
          showLoading={setShowLoading}
          values={item}
          setValues={setItem}
        />
        <Table
          {...tableProps}
          childComponents={{
            cellText: {
              content: (props) => {
                if (props.column.key === "actions") {
                  return (
                    <Dropdown className="actions-dropdown">
                      <Dropdown.Toggle id="dropdown-basic">
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => edit(props.rowData)}>
                          Editar
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => deleted(props.rowData)}>
                          Eliminar
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  );
                }
              },
            },
          }}
          data={data}
        />
      </div> */}
    </Admin>
  );
}

export default Customers;
