import './Loading.scss';

function Loading({setShow}) {
  return (
    <div className={`loading ${setShow && 'loading-show'}`}>
      <div className="loading__spinner">
        <div>
          <img src="../../assets/img/favicon.png" alt="logo" />
        </div>
        <div>
          <img src="../../assets/img/favicon.png" alt="logo" />
        </div>
      </div>
    </div>
  );
}

export default Loading;
