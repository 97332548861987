import { useEffect, useState } from "react";
import Admin from "../Admin";
import { Table } from "ka-table";
import { useNavigate } from "react-router-dom";
import { DataType } from "ka-table/enums";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./Delivery.scss";
import { Dropdown } from "react-bootstrap";
import DeliveryEdit from "../../../modals/admin/deliveryedit/DeliveryEdit";

function Delivery() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [item, setItem] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [config, setConfig] = useState([]);
  const [data, setData] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const account = JSON.parse(localStorage.getItem("account"));
  const companyId = account.access.at(0);
  const [companyName, setCompanyName] = useState(null);

  useEffect(() => {
    (async () => {
      const config = await firebase
        .firestore()
        .collection("config")
        .doc("deliveryStatuses")
        .get();
      if (config.exists) {
        const data = config.data();
        if (data && data.list) {
          setConfig(data.list);
        } else {
          console.log(
            "La propiedad 'list' no está definida en los datos devueltos por la consulta a Firebase"
          );
        }
      } else {
        console.log(
          "No se encontró ningún documento con el ID 'deliveryStatuses' en la colección 'config'"
        );
      }
      const carrier = await firebase.firestore().collection("carriers").get();
      const docs = [];
      carrier.forEach((doc) => {
        docs.push({
          id: doc.id,
          name: doc.data().name,
        });
      });
      setCarriers(docs);
    })();
  }, []);

  useEffect(() => {
    firebase
      .firestore()
      .collection("companies")
      .doc(companyId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setCompanyName(doc.data().name);
        }
      });

    if (carriers.length > 0) {
      const delivery = firebase
        .firestore()
        .collection("companies")
        .doc(companyId)
        .collection("delivery");
      delivery.onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          const status = config.find(
            (x) => x.code === doc.data().deliveryStatus
          );
          const carrier = carriers.find((x) => x.id === doc.data().carrierId);
          docs.push({
            id: doc.id,
            orderId: doc.data().orderId,
            deliveryDate: doc.data().deliveryDate,
            statusName: status && status.name,
            deliveryStatus: doc.data().deliveryStatus,
            carrierName: carrier && carrier.name,
            carrierId: doc.data().carrierId,
            address: doc.data().address,
            customerData: doc.data().customerData,
            orderNumber: doc.data().orderNumber,
          });
        });
        setData(docs);
        setLoaded(true);
      });
    }
  }, [carriers, config]);

  const tableProps = {
    columns: [
      {
        key: "id",
        title: "ID",
        dataType: DataType.String,
        style: { width: 50 },
      },
      {
        key: "orderNumber",
        title: "N° de orden",
        dataType: DataType.String,
        style: { width: 50 },
      },
      {
        key: "customerData.name",
        title: "Cliente",
        dataType: DataType.String,
        style: { width: 70 },
      },
      {
        key: "customerData.phone",
        title: "Teléfono del cliente",
        dataType: DataType.String,
        style: { width: 100 },
      },
      {
        key: "statusName",
        title: "Estado de entrega",
        dataType: DataType.String,
        style: { width: 100 },
      },
      {
        key: "deliveryDate",
        title: "Fecha de entrega",
        dataType: DataType.String,
        style: { width: 100 },
      },
      {
        key: "carrierName",
        title: "Repartidor",
        dataType: DataType.String,
        style: { width: 100 },
      },
      {
        key: "actions",
        title: "",
        dataType: DataType.String,
        style: { width: 100 },
      },
    ],
    rowKeyField: "id",
    format: ({ column, value }) => {
      if (column.key === "id") {
        return value.slice(0, 7);
      }

      if (column.key === "carrierName") {
        if (!value) {
          return "Sin Asignar";
        } else {
          return value;
        }
      }

      if (column.key === "deliveryDate") {
        if (!value) {
          return "Sin Asignar";
        } else {
          return value;
        }
      }

      if (column.key === "orderNumber") {
        return (
          <div
            onClick={() =>
              navigate(
                `/admin/orderdetail/${
                  data.filter((x) => x.orderNumber === value && x.orderId)[0]
                    .orderId
                }`
              )
            }
            className="link-primary cursor-pointer"
          >
            N°{value}
          </div>
        );
      }

      return value;
    },
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const edit = (item) => {
    setItem({
      id: item.id,
      orderId: item.orderId,
      deliveryDate: item.deliveryDate,
      deliveryStatus: item.deliveryStatus,
      carrierId: item.carrierId,
      address: item.address,
      customerData: item.customerData,
    });
    toggleModal();
  };

  const sendWhatsappNotification = (item) => {
    const defaultMessage =
      "Hola, tu pedido de " +
      companyName +
      " se agendó para el dia " +
      item.deliveryDate;
    const message = encodeURIComponent(`${defaultMessage}`);
    window.open(
      `https://api.whatsapp.com/send?phone=${item.customerData.phone}&text=${message}`,
      "_blank"
    );
  };

  return (
    <Admin showLoading={showLoading}>
      <h1 className="text-3xl mt-5 text-center sm:bg-blue-500 sm:w-fit sm:pl-10 sm:-ml-10 sm:pr-5 sm:rounded-full sm:py-2 font-bold sm:text-white dark:text-gray-100">
        Entregas
      </h1>
      {/* HEADER */}
      <div className="w-96 items-center py-5 px-1">
        {/* <button
          onClick={toggleModal}
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          Agregar
          <i className="fas fa-plus ml-2"></i>
        </button> */}
        <form>
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Buscar entrega"
              required
            />
            <button
              type="submit"
              className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Buscar
            </button>
          </div>
        </form>
      </div>
      <div className="relative h-96 overflow-x-auto overflow-y-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
                N° de orden
              </th>
              <th scope="col" className="px-6 py-3">
                Cliente
              </th>
              <th scope="col" className="px-6 py-3">
                Teléfono del cliente
              </th>
              <th scope="col" className="px-6 py-3">
                Estado de entrega
              </th>
              <th scope="col" className="px-6 py-3">
                Fecha de entrega
              </th>
              <th scope="col" className="px-6 py-3">
                Repartidor
              </th>
              <th scope="col" className="px-1 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                key={item.id}
              >
                <th
                  scope="row"
                  className="px-3 text-left py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {item.id.slice(0, 7)}
                </th>
                <td className="px-6 py-4">
                  <div
                    className="text-blue-700 cursor-pointer active:text-violet-800"
                    onClick={() =>
                      navigate(`/admin/orderdetail/${item.orderId}`)
                    }
                  >
                    N°{item.orderNumber}
                  </div>
                </td>
                <td className="px-6 py-4">{item.customerData.name}</td>
                <td className="px-6 py-4">{item.customerData.phone}</td>
                <td className="px-6 py-4">{item.statusName}</td>
                <td className="px-6 py-4">{item.deliveryDate}</td>
                <td className="px-6 py-4">{item.carrierName}</td>
                <td className="px-6 py-4 cursor-pointer">
                  <i className="fa-solid fa-caret-down"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* 
      <div className="admin__content__header">
        <div className="admin__content__header__title">Entregas</div>
      </div>
      <div className="admin__content__body">
        {loaded && (
          <div>
            <DeliveryEdit
              setShow={showModal}
              toggleModal={toggleModal}
              showLoading={setShowLoading}
              values={item}
              setValues={setItem}
              status={config}
            />
            {data.length > 0 ? (
              <Table
                {...tableProps}
                childComponents={{
                  cellText: {
                    content: (props) => {
                      if (props.column.key === "actions") {
                        return (
                          <>
                            <Dropdown className="actions-dropdown">
                              <Dropdown.Toggle id="dropdown-basic">
                                <i className="fa-solid fa-ellipsis-vertical"></i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => edit(props.rowData)}
                                >
                                  Editar
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    sendWhatsappNotification(props.rowData)
                                  }
                                >
                                  Notificar por Whatsapp
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                        );
                      }
                    },
                  },
                }}
                data={data}
              />
            ) : (
              <div className="empty-content">
                <i className="fa-regular fa-folder-open"></i>
                <div className="empty-content__body">
                  Aún no hay entregas para mostrar
                </div>
              </div>
            )}
          </div>
        )}
      </div> */}
    </Admin>
  );
}
export default Delivery;
