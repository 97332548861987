import { useState } from "react";
import {
  Cart,
  cartItems,
  DeleteItems,
  CartPrice,
  compName,
} from "../../../services/cart.js";
import { useNavigate, useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./Checkout.scss";
//ES PARA VER SI SE AGREGA LA RAMA QUE NO SE AGREGO
export default function Checkout() {
  const company = compName[0];
  const companyId = useParams();
  const navigate = useNavigate();
  const [cartItem, setCartItem] = useState(cartItems);
  const [qty, setQty] = useState(0);
  const [text, setText] = useState("");
  const [filterClient, setFilterClient] = useState({});
  const [customerPhone, setCustomerPhone] = useState(0);
  const [correlativeOrderNumber, setCorrelativeOrderNumber] = useState(0);
  const [customerInfo, setCustomerInfo] = useState({
    name: "",
    phone: "",
    address: "",
    email: "",
    rut: "",
    lat: "",
    lng: "",
    id: "",
  });

  const handleDeleteItem = async (id) => {
    await DeleteItems(id, setCartItem);
  };

  const handleCustomerInfo = (e) => {
    if (!filterClient) {
      console.log("ya esta la info del cliente");
    } else {
      const { name, value } = e.target;
      console.log(name, value);
      setCustomerInfo({
        ...customerInfo,
        [name]: value,
        phone: customerPhone,
      });
    }
  };

  const handleConfirmOrder = async () => {
    const correlative = correlativeOrderNumber;
    console.log(customerInfo);
    console.log("confirm order", cartItems);
    console.log(correlative);
    try {
      const doc2 = firebase
        .firestore()
        .collection("companies")
        .doc(companyId.companyId);
      const db = firebase.firestore();
      const items = cartItems.map((item) => {
        return {
          brand: item.brand,
          id: item.id,
          name: item.name,
          price: item.price,
          cartQty: item.cartQty,
        };
      });
      const order = {
        customer: customerInfo,
        total: CartPrice().subTotal,
        items: items,
        status: 100,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: "customer",
        deletedBy: null,
        deletedAt: null,
        iva: 0,
        subtotal: CartPrice().subTotal,
        correlativeNumber: correlative,
      };
      const doc = db
        .collection("companies")
        .doc(companyId.companyId)
        .collection("orders")
        .doc();
      order.id = doc.id;
      doc.set(order, { merge: true });
      await doc2.set({ correlativeOrder: correlative + 1 }, { merge: true });
      Cart([], 0);
      navigate("/customer/order");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = (e, item) => {
    const currentCartItem = cartItems.find(
      (cartItem) => cartItem.id === item.id
    );

    if (e.target.id === "add") {
      if (currentCartItem && currentCartItem.cartQty < item.stock) {
        Cart(item, currentCartItem.cartQty + 1);
        setQty(currentCartItem.cartQty + 1);
      } else if (!currentCartItem < item.stock) {
        Cart(item, 1);
        setQty(1);
      }
    } else {
      if (currentCartItem && currentCartItem.cartQty > 1) {
        Cart(item, currentCartItem.cartQty - 1);
        setQty(currentCartItem.cartQty - 1);
      }
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const maxLength = 12;
    const regex = /^(\d)(\d{4})(\d{4})$/;
    const result = inputValue
      .substring(0, maxLength)
      .replace(regex, "$1 $2 $3");
    setText(
      result
        .trim()
        .replace(/\s/g, "")
        .replace(/(\d)(?=(\d{4})+$)/g, "$1 ")
    );
    let phoneNumber = result.trim().replace(/\s/g, "");

    if (phoneNumber.length === 9) {
      const db = firebase.firestore();
      const fetchData = async () => {
        const data = await db
          .collection("companies")
          .doc(companyId.companyId)
          .collection("customers")
          .where("phone", "==", phoneNumber)
          .get();
        const client = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        if (client.length > 0) {
          console.log("hay cliente");
          setFilterClient(...client);
          setCustomerInfo({
            name: client[0].name,
            phone: client[0].phone,
            address: client[0].address,
            email: client[0].email,
            rut: client[0].rut,
            lat: client[0].lat,
            lng: client[0].lng,
            id: client[0].id,
          });
        } else {
          console.log("no hay cliente");
          setCustomerPhone(phoneNumber);
          setFilterClient({});
        }
      };
      fetchData();
    } else {
      setFilterClient({});
    }
  };

  const correlativeOrder = firebase
    .firestore()
    .collection("companies")
    .doc(companyId.companyId);

  correlativeOrder.onSnapshot((snap) => {
    if (!snap.empty) {
      const data = snap.data().correlativeOrder;
      setCorrelativeOrderNumber(data);
    }
  });

  return (
    <div className="container mx-auto relative">
      <nav className="flex dark:bg-slate-900 items-center relative justify-between bg-white px-5 py-6 w-full">
        <button
          className="flex text-sm items-center text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-500"
          onClick={() => navigate(-1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-chevron-left"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z"></path>
            <polyline points="15 6 9 12 15 18"></polyline>
          </svg>
          <div className="">Volver</div>
        </button>
        <div className="w-8">
          <img src="../assets/img/favicon.png" alt="logo" />
        </div>
      </nav>
      <div className="xl:w-full  min-h-[calc(100vh-138px)] relative pb-14 p-3 ">
        <div className="gap-4">
          <div className="sm:col-span-12  md:col-span-12 lg:col-span-4 xl:col-span-4  mb-4">
            <div className="bg-white dark:bg-slate-800 shadow rounded-md w-full relative">
              <div className="border-b border-dashed border-slate-200 dark:border-slate-700 py-3 px-4 dark:text-slate-300/70">
                <div className="flex-none md:flex">
                  <h4 className="font-medium flex-1 self-center mb-2 md:mb-0">
                    Carrito de compras
                  </h4>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full">
                        <thead className="bg-gray-50 dark:bg-slate-600/20">
                          <tr>
                            <th
                              scope="col"
                              className="p-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                            >
                              Productos
                            </th>
                            <th
                              scope="col"
                              className="p-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                            >
                              Cant.
                            </th>
                            <th
                              scope="col"
                              className="p-3 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                            >
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartItem.map((item) => (
                            <tr
                              className="bg-white border-b border-dashed dark:bg-gray-800 dark:border-gray-700"
                              key={item.id}
                            >
                              <td className="p-3 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-slate-300">
                                <h5 className="text-sm w-36 truncate font-semibold text-slate-700 dark:text-gray-400 inline-block">
                                  {item.name}
                                </h5>
                                <br></br>
                                <a
                                  className="text-red-500"
                                  onClick={() => handleDeleteItem(item.id)}
                                >
                                  Eliminar
                                </a>
                              </td>
                              <td className="p-3 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                <div className="flex items-center">
                                  <button
                                    id="less"
                                    onClick={(e) => handleAdd(e, item)}
                                    className="bg-gray-100 dark:bg-slate-700 rounded-full p-2 focus:outline-none"
                                  >
                                    -
                                  </button>
                                  <span className="mx-2 text-gray-700 dark:text-gray-400">
                                    {item.cartQty}
                                  </span>
                                  <button
                                    id="add"
                                    onClick={(e) => handleAdd(e, item)}
                                    className="bg-gray-100 dark:bg-slate-700 rounded-full p-2 focus:outline-none"
                                  >
                                    +
                                  </button>
                                </div>
                              </td>
                              <td className="p-3 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                {parseInt(item.price).toLocaleString("es-CL", {
                                  style: "currency",
                                  currency: "CLP",
                                })}
                              </td>
                            </tr>
                          ))}
                          <tr className="bg-white dark:bg-gray-800">
                            <td className="p-3 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-slate-300">
                              Total
                            </td>
                            <td className="p-3 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-slate-300"></td>
                            <td className="p-3 text-sm font-semibold text-gray-900 whitespace-nowrap dark:text-slate-300">
                              {CartPrice().subTotal.toLocaleString("es-CL", {
                                style: "currency",
                                currency: "CLP",
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:col-span-12  md:col-span-12 lg:col-span-8 xl:col-span-8 ">
            <div className="bg-white dark:bg-slate-800 shadow  rounded-md w-full relative mb-4">
              <div className="border-b border-dashed border-slate-200 dark:border-slate-700 py-3 px-4 dark:text-slate-300/70">
                <h4 className="font-medium">Información del cliente</h4>
              </div>
              <div className="flex-auto p-4">
                <div className="col-span-4 md:col-span-2 lg:col-span-1 xl:col-span-1">
                  <div className="mb-2">
                    <label
                      htmlFor="Mobile_No"
                      className="font-medium text-sm text-slate-600 dark:text-slate-400"
                    >
                      Numero de telefono
                      <small className="text-red-600 text-sm">*</small>
                    </label>
                    <input
                      className="form-input w-full rounded-md mt-1 border border-slate-300/60 dark:border-slate-700 dark:text-slate-300 bg-transparent px-3 py-1 focus:outline-none focus:ring-0 placeholder:text-slate-400/70 placeholder:font-normal placeholder:text-sm hover:border-slate-400 focus:border-primary-500 dark:focus:border-primary-500  dark:hover:border-slate-700"
                      placeholder="9 1234 5678"
                      value={text}
                      maxLength={10}
                      onChange={handleChange}
                      type="text"
                      name="phone"
                      pattern="[0-9 ]+"
                      required
                    ></input>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-4 md:col-span-2 lg:col-span-2 xl:col-span-2">
                    <div className="mb-2">
                      <label
                        htmlFor="First_Name"
                        className="font-medium text-sm text-slate-600 dark:text-slate-400"
                      >
                        Nombre
                        <small className="text-red-600 text-sm">*</small>
                      </label>
                      <input
                        className="form-input w-full rounded-md mt-1 border border-slate-300/60 dark:border-slate-700 dark:text-slate-300 bg-transparent px-3 py-1 focus:outline-none focus:ring-0 placeholder:text-slate-400/70 placeholder:font-normal placeholder:text-sm hover:border-slate-400 focus:border-primary-500 dark:focus:border-primary-500  dark:hover:border-slate-700"
                        placeholder="Nombre"
                        type="text"
                        required
                        name="name"
                        onChange={(e) => handleCustomerInfo(e)}
                        value={filterClient ? filterClient.name : ""}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-span-4 md:col-span-2 lg:col-span-2 xl:col-span-2">
                  <div className="mb-2">
                    <label
                      htmlFor="Email_Address"
                      className="font-medium text-sm text-slate-600 dark:text-slate-400"
                    >
                      Email<small className="text-red-600 text-sm">*</small>
                    </label>
                    <input
                      className="form-input w-full rounded-md mt-1 border border-slate-300/60 dark:border-slate-700 dark:text-slate-300 bg-transparent px-3 py-1 focus:outline-none focus:ring-0 placeholder:text-slate-400/70 placeholder:font-normal placeholder:text-sm hover:border-slate-400 focus:border-primary-500 dark:focus:border-primary-500  dark:hover:border-slate-700"
                      placeholder="Ingrese Email"
                      type="email"
                      required
                      name="email"
                      onChange={(e) => handleCustomerInfo(e)}
                      value={filterClient ? filterClient.email : ""}
                    ></input>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-4 md:col-span-2 lg:col-span-2 xl:col-span-2">
                    <div className="mb-2">
                      <label
                        htmlFor="Delivery_Address"
                        className="font-medium text-sm text-slate-600 dark:text-slate-400"
                      >
                        Dirección
                        <small className="text-red-600 text-sm">*</small>
                      </label>
                      <input
                        className="form-input w-full rounded-md mt-1 border border-slate-300/60 dark:border-slate-700 dark:text-slate-300 bg-transparent px-3 py-1 focus:outline-none focus:ring-0 placeholder:text-slate-400/70 placeholder:font-normal placeholder:text-sm hover:border-slate-400 focus:border-primary-500 dark:focus:border-primary-500  dark:hover:border-slate-700"
                        placeholder="Dirección de envio"
                        type="text"
                        name="address"
                        onChange={(e) => handleCustomerInfo(e)}
                        value={filterClient ? filterClient.address : ""}
                        required
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-4 md:col-span-2 lg:col-span-1 xl:col-span-1">
                    <div className="mb-2">
                      <label
                        htmlFor="City"
                        className="font-medium text-sm text-slate-600 dark:text-slate-400"
                      >
                        Comuna<small className="text-red-600 text-sm">*</small>
                      </label>
                      <input
                        className="form-input w-full rounded-md mt-1 border border-slate-300/60 dark:border-slate-700 dark:text-slate-300 bg-transparent px-3 py-1 focus:outline-none focus:ring-0 placeholder:text-slate-400/70 placeholder:font-normal placeholder:text-sm hover:border-slate-400 focus:border-primary-500 dark:focus:border-primary-500  dark:hover:border-slate-700"
                        placeholder="Comuna"
                        type="text"
                        name="area"
                        onChange={(e) => handleCustomerInfo(e)}
                        value={filterClient ? filterClient.area : ""}
                        required
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-4"></div>
                <div className="mt-3 flex justify-end">
                  <button
                    className="inline-block focus:outline-none text-primary-500 hover:bg-blue-500 hover:text-white bg-blue border border-gray-200 dark:bg-transparent dark:text-primary-500 dark:hover:text-white dark:border-gray-700 dark:hover:bg-primary-500  text-sm font-medium py-1 px-3 rounded"
                    onClick={handleConfirmOrder}
                  >
                    Confirmar orden
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 block print:hidden">
          <div className="flex flex-col items-center justify-center w-full h-16 text-sm text-slate-600 dark:text-slate-400 bg-white dark:bg-slate-900 border-t border-slate-200 dark:border-slate-800">
            <footer className="footer mt-4 rounded-tr-md rounded-tl-md bg-transparent py-4 text-center font-medium text-slate-600 dark:text-slate-400 md:text-left">
              tienda {company} - {new Date().getFullYear()}
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}
