import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Badge, Image } from "antd";
import Loading from "../../../components/loading/Loading";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./Shopping.scss";
import { Cart, cartItems, exportCompName } from "../../../services/cart.js";

function Shopping() {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [realItems, setRealItems] = useState([]);
  const [item, setItem] = useState([]);
  const [companyName, setCompanyName] = useState();
  const [activeItem, setActiveItem] = useState(null);
  const [qty, setQty] = useState(0);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const db = firebase.firestore();
    const fetchData = async () => {
      setShowLoading(true);
      const data = await db
        .collection("companies")
        .doc(companyId)
        .collection("products")
        .where("deletedAt", "==", null)
        .get();
      const products = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setRealItems(products);
      setItem(products);
      setShowLoading(false);
    };
    fetchData();

    firebase
      .firestore()
      .collection("companies")
      .doc(companyId)
      .onSnapshot((snap) => {
        exportCompName(snap.data().name);
        setCompanyName(snap.data().name);
      });
  }, []);

  const handleAdd = (e, item) => {
    const currentCartItem = cartItems.find(
      (cartItem) => cartItem.id === item.id
    );

    if (e.target.id === "add") {
      if (currentCartItem && currentCartItem.cartQty < item.stock) {
        Cart(item, currentCartItem.cartQty + 1);
        setQty(currentCartItem.cartQty + 1);
      } else if (!currentCartItem && qty < item.stock) {
        Cart(item, 1);
        setQty(1);
      }
    } else {
      if (currentCartItem && currentCartItem.cartQty > 1) {
        Cart(item, currentCartItem.cartQty - 1);
        setQty(currentCartItem.cartQty - 1);
      }
    }
  };

  const handleActiveItem = (itemId) => {
    console.log("funciono");
    setQty(cartItems.find((cartItem) => cartItem.id === itemId)?.cartQty || 0);
  };

  const handleCart = () => {
    console.log("hay ", cartItems.length, " item en el carrito");
    navigate(`/carro/${companyId}`);
  };

  const handleSearch = (itemSearch = "") => {
    if (itemSearch.target.value === "") {
      setItem(realItems);
      return;
    }
    const itemFiltered = itemSearch.target.value.toLocaleLowerCase();
    console.log(itemFiltered);
    const filtered = realItems.filter((item) => {
      return item.name.toLocaleLowerCase().includes(itemFiltered);
    });
    setItem(filtered);

    if (itemSearch.target.value !== "" && filtered.length === 0) {
      setItem([]);
    }
  };

  return (
    <div className="container mx-auto relative">
      <nav className="fixed z-50 space-x-10 py-3 bg-white items-center flex top-0 left-0 right-0 justify-center">
        <div className="w-8">
          <img src="../assets/img/favicon.png" alt="logo" />
        </div>
        <div className="flex gap-3 items-center">
          <input
            className="border border-gray-300 dark:border-gray-700 rounded-full shadow-xl px-3 py-2 focus:outline-none focus:ring-1 focus:ring-gray-500"
            type="text"
            placeholder="Buscar producto"
            onChange={handleSearch}
          />
        </div>
        <Badge count={cartItems.length} size="small">
          <div className="flex gap-3 items-center" onClick={handleCart}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="dark:fill-white"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z"
                fill="#200E32"
              />
            </svg>
          </div>
        </Badge>
      </nav>
      <Loading setShow={showLoading} />
      <div className="flex justify-center	relative px-4 pt-[5.5em] duration-300">
        {/** tarjeta de producto */}
        <div className="min-h-[calc(100vh-138px)] relative border-red">
          {item.length > 0
            ? item.map((item) => (
                <div key={item.id}>
                  <div className="w-full">
                    <div className="shadow hover:shadow-lg transition duration-300 ease-in-out xl:mb-0 lg:mb-0 md:mb-0 mb-6 ">
                      <div className="overflow-hidden relative">
                        {/* <img
                          className="w-full transition duration-700 ease-in-out w-full h-80 object-cover  "
                          src={
                            item.images && item.images.length > 0
                              ? item.images[0].url
                              : "../../assets/img/favicon.png"
                          }
                          alt="image"
                        /> */}
                        <Image
                          style={{ objectFit: "cover" }}
                          width={"full"}
                          height={"20rem"}
                          alt="imagen del producto"
                          src={
                            item.images && item.images.length > 0
                              ? item.images[0].url
                              : "../../assets/img/favicon.png"
                          }
                        />
                      </div>
                      <div className="px-4 py-3 bg-white">
                        <h1 className="text-blue-500 font-semibold text-lg">
                          {item.name}
                        </h1>
                        <div className="flex py-2 justify-between items-center">
                          <div className="text-sm text-gray-600">
                            {new Intl.NumberFormat("es-CL", {
                              style: "currency",
                              currency: "CLP",
                            }).format(item.price)}
                          </div>
                          {item.stock > 0 ? (
                            <div>
                              <button
                                id="less"
                                onClick={(e) => handleAdd(e, item)}
                                className="bg-gray-100 dark:bg-slate-700 rounded-full p-2 focus:outline-none"
                              >
                                -
                              </button>
                              <span className="mx-2 text-gray-700 dark:text-gray-400">
                                {cartItems.length > 0 &&
                                cartItems.find(
                                  (cartItem) => cartItem.id === item.id
                                ) !== undefined
                                  ? cartItems.find(
                                      (cartItem) => cartItem.id === item.id
                                    ).cartQty
                                  : 0}
                              </span>
                              <button
                                id="add"
                                onClick={(e) => handleAdd(e, item)}
                                className="bg-gray-100 dark:bg-slate-700 rounded-full p-2 focus:outline-none"
                              >
                                +
                              </button>
                            </div>
                          ) : (
                            <div className="text-red-500">Sin stock</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : showLoading === false && (
                <div className="w-full h-screen flex justify-center items-center">
                  <h1>no se ha encontrado el producto :(</h1>
                </div>
              )}
        </div>
      </div>
    </div>
  );
}

export default Shopping;
