import "./Login.scss";
import Loading from "../../components/loading/Loading";

function Login({ children, showLoading }) {
  return (
    <>
      <Loading setShow={showLoading} />
      {children}
    </>
  );
}

export default Login;
