import './Account.scss';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

function Account({setShow, toggleModal, showLoading, values, setValues, restaurants, suppliers}) {
  const { reset, setValue, getValues, control, register, handleSubmit, formState: { errors } } = useForm();
  const [access, setAccess] = useState([]);
  
  const onEnter = async () => {
    console.log("onEnter", values);
    if (values.id) {
      setAccess(eval(`${values.role}s`));
      values.access = eval(`${values.role}s`).filter(item => values.access.indexOf(item.value) !== -1);
    }
    setTimeout(() => {
      reset(values);
    });
    setValues({
      name: "",
      email: "",
      role: "",
      access: []
    });
  }

  const onSubmit = async data => {
    console.log(data);
    showLoading(true);
  	try {
      data.access = data.access.map((item) => item.value);
      data[`${data.role}_ids`] = data.access;
      console.log("data", data);
      if (data.id) {
        const doc = firebase.firestore().collection('accounts').doc(data.id);
        doc.update(data);
      } else {
        await firebase.functions().httpsCallable('createAccounts')(data);
      }
	  	alert('Registro guardado exitosamente');
      toggleModal();
	  } catch (e) {
    	alert(e.message);
    }
    showLoading(false);
  }

  const changeRole = event => {
    console.log("event", event.target.value);
    setValue('access', []);
    setAccess(eval(`${event.target.value}s`));
    /*if (event.target.value === 'supplier') {
      setAccess(suppliers);
    } else {
      setAccess(restaurants);
    }*/
  }
  
  return (
    <Modal show={setShow} onHide={toggleModal} onEnter={onEnter} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Cuenta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="login__content__form__body">
          <div className="row">
            <div className="col">
              <label htmlFor="name">Nombre</label>
              <input type="text" className={`form-control ${errors.name && 'is-invalid'}`} placeholder="Juanito Pérez" id="name" {...register("name", { required: true })} />
              {errors.name && errors.name.type === "required" && <span className="text-danger">Este campo es requerido</span>}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="email">Correo electrónico</label>
              <input disabled={getValues('id')} type="text" className={`form-control ${errors.email && 'is-invalid'}`} placeholder="juanito.perez@gmail.com" id="email" {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
              {errors.email && errors.email.type === "required" && <span className="text-danger">Este campo es requerido</span>}
              {errors.email && errors.email.type === "pattern" && <span className="text-danger">Formato inválido</span>}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="role">Rol</label>
              <select className={`form-select ${errors.role && 'is-invalid'}`} id="role" {...register("role", { required: true, onChange: changeRole })}>
                <option value="">Seleccionar</option>
                <option value="supplier">Proveedor</option>
                <option value="restaurant">Restaurant</option>
              </select>
              {errors.role && errors.role.type === "required" && <span className="text-danger">Este campo es requerido</span>}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="access">Accesos</label>
              <Controller name="access" control={control} rules={{ required: true }} setValue={setValue} render={({ field: { onChange, value }}) => (
                <Select
                  isMulti
                  options={access}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={onChange}
                  //onChange={val => onChange(val.map(c => c.value))}
                  placeholder="Seleccionar"
                  value={value}
                  noOptionsMessage={() => 'Sin opciones'}
                />
              )} />
              {errors.access && errors.access.type === "required" && <span className="text-danger">Este campo es requerido</span>}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={toggleModal}>
          Cancelar
        </button>
        <button type="button" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
          Guardar
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default Account;
