import { useEffect, useState } from "react";
import "./Carriers.scss";
import Admin from "../Admin";
import Carrier from "../../../modals/admin/carrier/Carrier";
import { Table } from "ka-table";
import { DataType } from "ka-table/enums";
import Dropdown from "react-bootstrap/Dropdown";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Swal from "sweetalert2";

function Carriers() {
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const tableProps = {
    columns: [
      { key: "name", title: "Nombre", dataType: DataType.String },
      { key: "email", title: "Correo electrónico", dataType: DataType.String },
      { key: "phone", title: "Telefono", dataType: DataType.Number },
      { key: "address", title: "Dirección", dataType: DataType.String },
      { key: "area", title: "Comuna", dataType: DataType.String },
      { key: "actions", title: "", dataType: DataType.String, width: 100 },
    ],
    rowKeyField: "id",
  };
  const [data, setData] = useState([]);
  const [item, setItem] = useState({});

  useEffect(() => {
    const ubication = firebase
      .firestore()
      .collection("carriers")
      .where("deletedAt", "==", null);
    if (ubication != null) {
      ubication.onSnapshot((snap) => {
        if (!snap.empty) {
          const data = snap.docs.map((item, index) => ({
            name: item.data().name,
            email: item.data().email,
            phone: item.data().phone,
            address: item.data().address,
            area: item.data().area,
            id: item.id,
          }));
          setData(data);
        }
      });
    } else {
      setData([]);
    }
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const edit = (item) => {
    console.log(item);
    setShowModal(true);
    setItem(item);
  };

  const deleted = (item) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        firebase
          .firestore()
          .collection("carriers")
          .doc(item.id)
          .update({
            deletedAt: firebase.firestore.Timestamp.now(),
            deletedBy: firebase.auth().currentUser.uid,
          })
          .then(() => {
            Swal.fire(
              "Eliminado",
              "El repartidor ha sido eliminado",
              "success"
            );
          })
          .catch((error) => {
            Swal.fire("Error", error.message, "error");
          });
      }
    });
  };

  return (
    <Admin showLoading={showLoading}>
      <h1 className="text-3xl mt-5 text-center sm:bg-blue-500 sm:w-fit sm:pl-10 sm:-ml-10 sm:pr-5 sm:rounded-full sm:py-2 font-bold sm:text-white dark:text-gray-100 ">
        Repartidores
      </h1>
      <div className="botones flex w-full sm:justify-end items-center py-3 px-1">
        <button
          onClick={toggleModal}
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          Agregar
          <i className="fas fa-plus ml-2"></i>
        </button>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Nombre
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Teléfono
              </th>
              <th scope="col" className="px-6 py-3">
                Dirección
              </th>
              <th scope="col" className="px-6 py-3">
                Comuna
              </th>
              <th scope="col" className="px-1 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                key={item.id}
              >
                <th
                  scope="row"
                  className="px-6 text-left py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {item.name}
                </th>
                <td className="px-4 py-4">{item.email}</td>
                <td className="px-6 py-4">{item.phone}</td>
                <td className="px-6 py-4">{item.address}</td>
                <td className="px-6 py-4">{item.area}</td>
                <td className="px-6 py-4 cursor-pointer">
                  <i className="fa-solid fa-caret-down"></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className="admin__content__header">
        <div className="admin__content__header__title">Repartidores</div>
        <div className="admin__content__header__actions">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShowModal(true)}
          >
            Agregar repartidor
          </button>
        </div>
      </div>
      <div className="admin__content__body">
        <Carrier
          setShow={showModal}
          toggleModal={toggleModal}
          showLoading={setShowLoading}
          values={item}
          setValues={setItem}
        />
        <Table
          {...tableProps}
          childComponents={{
            cellText: {
              content: (props) => {
                if (props.column.key === "actions") {
                  return (
                    <Dropdown className="actions-dropdown">
                      <Dropdown.Toggle id="dropdown-basic">
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => edit(props.rowData)}>
                          Editar
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => deleted(props.rowData)}>
                          Eliminar
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  );
                }
              },
            },
          }}
          data={data}
        />
      </div> */}
    </Admin>
  );
}

export default Carriers;
