import "./ResetPassword.scss";
import Login from "../Login";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "../../../firebase";
import "firebase/compat/auth";

function ResetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showLoading, setShowLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setShowLoading(true);
    try {
      await firebase.auth().sendPasswordResetEmail(data.email);
      alert(
        "Te hemos enviado un email con las instrucciones para reiniciar tu contraseña"
      );
      navigate("/login");
    } catch (e) {
      let message = "Se ha producido un error, intenta nuevamente";
      if (
        [
          "auth/user-not-found",
          "auth/wrong-password",
          "auth/too-many-requests",
          "auth/user-disabled",
        ].indexOf(e.code) !== -1
      ) {
        message = "Tu email es incorrecto";
      }
      alert(message);
    }
    setShowLoading(false);
  };

  return (
    <Login showLoading={showLoading}>
      <div className="login__content__form__title">
        Ingresa la información requerida para recuperar tu contraseña
      </div>
      <form
        className="login__content__form__body"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          <div className="col">
            <label htmlFor="email">Correo electrónico</label>
            <input
              type="text"
              className={`form-control ${errors.email && "is-invalid"}`}
              placeholder="juanito.perez@gmail.com"
              id="email"
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.email && errors.email.type === "required" && (
              <span className="text-danger">Este campo es requerido</span>
            )}
            {errors.email && errors.email.type === "pattern" && (
              <span className="text-danger">Formato inválido</span>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <button className="btn btn-primary" type="submit">
              Enviar instrucciones
            </button>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <a href="/login">Volver a iniciar sesión</a>
          </div>
        </div>
      </form>
    </Login>
  );
}

export default ResetPassword;
